/**
 * Component classes registered by plugins.
 *
 */

@import 'print.css';

@import 'tables.css';

@import 'prism.css';

@import 'tailwindcss/components';

@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&family=Saira+Stencil+One&display=swap');

.cstm_breadcrumbs_holder {
    padding: 28px 0 16px;
}
.cstm_breadcrumbs_holder nav ol li {
    font-family: "Inter", sans-serif !important;
    font-size: 12px !important;
    color: #1d2939;
    text-transform: capitalize;
}
.cstm_breadcrumbs_holder nav ol li a {
    font-family: "Inter", sans-serif !important;
    font-size: 12px !important;
    color: #667085;
}
.main_page_heading {
    font-family: "Archivo", sans-serif !important;
    font-size: 24px !important;
    font-weight: 600 !important;
    color: #1d2939;
}
.main_page_heading .extra_sub_heading {
    font-family: "Inter", sans-serif !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #1d2939;
    padding-left: 4px;
}
.sub_main_heading_holder {
    font-family: "Archivo", sans-serif !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    color: #1d2939 !important;
}
.sub_main_heading_holder + .sub_txt,
.sub_content_holder {
    font-family: "Inter", sans-serif !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #667085;
}
.thin_scrollbar_holder {
    overflow: auto;
}
.thin_scrollbar_holder::-webkit-scrollbar {
    width: 8px;
}
.thin_scrollbar_holder::-webkit-scrollbar-track {
    background-color: #f0f0f0;
    border-radius: 20px;
}
.thin_scrollbar_holder::-webkit-scrollbar-thumb {
    background-color: #a2a3a3;
    border-radius: 20px;
}
.primary_maroon_btn {
    background-color: #4b1f4d !important;
    color: white !important;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 500 !important;
    text-transform:none !important;
    font-family: "Inter", sans-serif !important;
    border: 1px solid #562658 !important;
    display: flex;
    align-items: center;
    border-radius: 4px !important;
    padding: 9px 16px !important;
    box-shadow: none !important;
}
.primary_maroon_btn .icon {
    font-size: 16px;
    padding-right: 4px;
}
.primary_maroon_outlined_btn {
    border: 1px solid #562658 !important;
    background-color: #F8ECFB !important;
    color: #562658 !important;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 500 !important;
    text-transform:none !important;
    font-family: "Inter", sans-serif !important;
    display: flex;
    align-items: center;
    border-radius: 4px !important;
    padding: 9px 16px !important;
    box-shadow: none !important;
}
.primary_maroon_outlined_btn.mr-10 {
    margin-right: 10px !important;
}
.primary_maroon_outlined_btn i.icon {
    color: #562658;
    font-size: 16px;
    font-weight: 400 !important;
    padding-right: 4px;
}
.primary_maroon_outlined_btn.only_icon_btn {
    padding: 8px !important;
    min-width: auto;
    box-sizing: border-box;
}
.primary_maroon_outlined_btn.only_icon_btn svg {
    width: 16px;
    height: 16px;
}
.icon_primary_btn {
    padding: 6px 7px !important;
}
.icon_primary_btn .icon {
    color: #fff !important;
    padding-right: 0;
}
.each_cat_card {
    width: calc(20% - 10px);
}
.cstm_drawer_holder {
    z-index: 1300 !important;
}
.cstm_drawer_holder.bottom_border .heading_holder {
    border-bottom: 1px solid #EAECF0;
}
.cstm_drawer_holder .heading_holder {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    padding: 15px 20px;
}
.cstm_drawer_holder .heading_holder .heading {
    font-family: "Archivo", sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: #1d2939;
    margin-bottom: 0;
}
.cstm_drawer_holder .cstm_wizard_holder {
    margin-top: 62px;
}
.cstm_drawer_holder .cstm_wizard_holder .box_holder {
    display: flex;
    flex-direction: column;
    /* height: 100vh; */
    max-height: calc(100vh - 140px);
    overflow: auto;
}
.cstm_drawer_holder .cstm_wizard_holder input {
    font-size: 14px !important;
}
.cstm_drawer_holder .MuiDrawer-paper {
    overflow-y: hidden;
}
.cstm_drawer_holder .cstm_wizard_holder .main_content_holder {
    /* height: calc(100vh - 100px); */
    height: 100%;
    overflow: auto;
}
.cstm_drawer_holder .cstm_wizard_holder .main_content_holder .main_heading {
    font-family: "Inter", sans-serif !important;
    font-size: 16px;
    font-weight: 600;
    padding: 28px 24px 12px 24px;
    background: #fff;
}
.cstm_drawer_holder .cstm_wizard_holder .main_content_holder .content_holder {
    /* height: calc(100% - 168px);
    overflow: auto; */
    padding: 0 24px 24px 24px;
}
.cstm_drawer_holder .cstm_wizard_holder .main_content_holder .content_holder .box_holder {
    max-height: 100%;
    overflow: hidden;
}
.cstm_drawer_holder .cstm_wizard_holder .main_content_holder .content_holder .box_holder .grid_container,
.cstm_drawer_holder .cstm_wizard_holder .main_content_holder .content_holder .grey_container {
    background: #f9fafb;
    padding: 12px;
    width: 100%;
    margin-left: 0;
}
.cstm_drawer_holder .cstm_wizard_holder .main_content_holder .content_holder .box_holder:nth-child(2) .grid_container {
    margin-top: 15px;
}
.cstm_drawer_holder .cstm_wizard_holder .main_content_holder .content_holder .box_holder:last-child .grid_container + .action_holder .add_another_holder {
    display: inline-block;
}
.cstm_drawer_holder .cstm_wizard_holder .main_content_holder .content_holder .box_holder .grid_container + .action_holder .remove_holder {
    display: inline-block;
    padding-bottom: 20px;
}
.cstm_drawer_holder .cstm_wizard_holder .main_content_holder .content_holder .box_holder .grid_container + .action_holder .add_another_holder {
    margin-bottom: 20px;
}
.cstm_drawer_holder .cstm_wizard_holder .main_content_holder .content_holder .box_holder .grid_container + .action_holder .remove_holder i {
    display: inline-block;
    color: red;
    font-size: 20px;
}
.cstm_drawer_holder .cstm_wizard_holder .stepper_holder {
    flex-wrap: wrap;
    padding: 20px 24px 0 24px;
}
.cstm_drawer_holder .cstm_wizard_holder .stepper_holder .each_step_holder {
    padding: 0;
    margin: 10px 0;
}
.cstm_drawer_holder .cstm_wizard_holder .stepper_holder .each_step_holder > button.MuiStepButton-horizontal {
    padding: 0 16px;
}
.cstm_drawer_holder .cstm_wizard_holder .stepper_holder .each_step_holder + .MuiStepConnector-horizontal {
    max-width: 20px;
}
.cstm_drawer_holder .cstm_wizard_holder .stepper_holder .each_step_holder + .MuiStepConnector-horizontal .MuiStepConnector-line {
    border: none;
    padding: 0 10px;
    position: relative;
}
.cstm_drawer_holder .cstm_wizard_holder .stepper_holder .each_step_holder + .MuiStepConnector-horizontal .MuiStepConnector-line::before {
    content: '\003E';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #98a2b3;
    font-size: 20px;
}
.cstm_drawer_holder .cstm_wizard_holder .stepper_holder .each_step_holder button .MuiStepLabel-iconContainer {
    display: none;
}
.cstm_drawer_holder .cstm_wizard_holder .main_content_holder .btn_holder {
    display: flex;
    justify-content: center;
    width: 100%;
    background: #fff;
    padding: 14px;
    position: absolute;
    bottom: 0;
    left: 0;
}
.cstm_drawer_holder .cstm_wizard_holder .main_content_holder .btn_holder button {
    width: 100%;
    padding: 12px 16px;
    font-family: "Inter", sans-serif !important;
    font-weight: 500;
    font-size: 12px;
    border-radius: 4px;
    margin: 0 6px;
}
.cstm_drawer_holder .cstm_wizard_holder .main_content_holder .btn_holder button.back_btn {
    background-color: #F8ECFB !important;
    color: #562658 !important;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 500 !important;
    text-transform:none !important;
    font-family: "Inter", sans-serif !important;
    border: 1px solid #562658 !important;
    display: flex;
    align-items: center;
    border-radius: 4px !important;
    padding: 9px 16px !important;
    box-shadow: none !important;
}
.cstm_drawer_holder .cstm_wizard_holder .main_content_holder .btn_holder button.next_btn {
    max-width: 372px;
    background-color: #4b1f4d !important;
    color: white !important;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 500 !important;
    text-transform:none !important;
    font-family: "Inter", sans-serif !important;
    border: 1px solid #562658 !important;
    display: flex;
    align-items: center;
    border-radius: 4px !important;
    padding: 9px 16px !important;
    box-shadow: none !important;
}
.cstm_drawer_holder .cstm_wizard_holder .stepper_holder .each_step_holder button .MuiStepLabel-labelContainer .MuiStepLabel-label {
    display: inline-block;
    background: transparent;
    color: #1d2939;
    border-radius: 4px;
    padding: 4px 8px;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 500;
}
.cstm_drawer_holder .cstm_wizard_holder .stepper_holder .each_step_holder button .MuiStepLabel-labelContainer .MuiStepLabel-label.Mui-active,
.cstm_drawer_holder .cstm_wizard_holder .stepper_holder .each_step_holder button .MuiStepLabel-labelContainer .MuiStepLabel-label.Mui-completed {
    background: #fdcccb;
    color: #1d2939 !important;
}
.date_pickr button svg {
    font-size: 20px;
}
.date_pickr_box .status_text + .MuiTextField-root {
    width: 100%;
}
.link_type_btn {
    font-family: "Inter", sans-serif !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    background: transparent !important;
    color: #562658 !important;
    text-transform: capitalize !important;
}
.required_span {
    font-size: 12px;
    font-weight: 500;
    color: #f04438;
}
.upload_imgBox {
    text-align: center;
}
.upload_imgBox .icon_holder .material-icons {
    font-size: 25px;
}
.upload_imgBox .file_info_content {
    display: inline-block;
    font-family: "Inter", sans-serif !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    color: #667085;
}
.upload_imgBox .file_info_content span {
    color: #562658;
}
.cstm_drawer_holder .progressbar_holder {
    width: 100%;
    position: absolute;
    left: 0;
    top: 62px;
    background: #ebedf1;
}
.cstm_drawer_holder .progressbar_holder .progress {
    height: 1.5px;
    background: #f76e6a;
    width: 0;
    transition: all 0.5s ease-in-out;
}
.canvas_holder {
    overflow: initial;
}
.canvas_holder canvas {
    width: 100% !important;
    object-fit: cover;
    border-radius: 16px !important;
}
.canvas_holder .mapboxgl-ctrl-bottom-left {
    display: none;
}
.map_outer_holder {
    padding-bottom: 16px;
    /* margin-bottom: 36px; */
    /* border-bottom: 1px solid #EAECF0; */
}
.map_acc_holder {
    position: relative;
    background: #fff;
    border-radius: 16px;
    box-shadow: 0px 24px 48px -12px rgba(16, 24, 40, 0.04);
}
.map_acc_holder .main_heading {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
    padding: 16px 20px;
    background: #fff;
    border-radius: 16px;
    box-shadow: 0px 24px 48px -12px rgba(16, 24, 40, 0.04);
}
.apexcharts-xaxis-label {
    color: #667085;
    fill: #667085;
    font-family: "Inter", sans-serif !important;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 160% */
}
.custom_apex_tooltip {
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 4px;
    background: #101828;
    box-shadow: 0px 17.82px 35.64px -8.91px rgba(16, 24, 40, 0.12);
}
.custom_apex_tooltip .circle {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    margin-right: 5px;
}
.custom_apex_tooltip .square {
    width: 10px;
    height: 10px;
    /* border-radius: 100%; */
    margin-right: 5px;
}
.custom_apex_tooltip .square + span {
    color: #fff;
}

.custom_apex_tooltip .circle + span {
    color: #fff;
}
.sub_list_holder a.fuse-list-item {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    margin-bottom: 4px;
    color: #667085;
}
.sub_list_holder a.fuse-list-item .fuse-list-item-icon {
    display: flex;
    color: #98A2B3;
    font-size: 16px;
}
.sub_list_holder a.fuse-list-item .fuse-list-item-text {
    margin: 0;
}
.sub_list_holder a.fuse-list-item .fuse-list-item-text .fuse-list-item-text-primary {
    display: inline-block;
    white-space: normal;
    color: #667085;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
}
.sub_list_holder a.fuse-list-item.active .fuse-list-item-text .fuse-list-item-text-primary,
.sub_list_holder a.fuse-list-item.active .fuse-list-item-icon {
    color: #1D2939;
}
.sub_list_holder .main_heading {
    color: #1D2939;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    padding: 4px 16px;
    padding-right: 32px;
    margin-bottom: 8px;
}
#fuse-navbar-side-panel {
    border-right: 1px solid #EAECF0;
}
#fuse-navbar-panel {
    width: auto;
    min-width: 220px;
    max-width: 220px;
    background: #fff;
    border-right: 1px solid #EAECF0;
}
#fuse-navbar-panel .close_holder {
    position: absolute;
    top: 32px;
    right: 12px;
    cursor: pointer;
    z-index: 1;
}
#fuse-navbar-panel .close_holder + .sub_list_outer_holder {
    padding: 32px 0;
}
.main_navbar_holder {
    padding: 0;
}
.main_navbar_holder .fuse-list-item {
    max-width: 70px;
    padding: 12px 0;
}
.ag-theme-alpine .ag-row-selected,
.ag-theme-alpine .ag-row-animation .ag-row.ag-after-created.ag-row-selected:hover,
.ag-theme-alpine .ag-row-animation .ag-row.ag-after-created:hover {
    background-color: rgba(33, 150, 243, 0.3) !important;
}
.cstm_dropwdown_holder {
    padding: 12px 0;
}
.cstm_dropwdown_holder .each_holder {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-family: "Inter", sans-serif !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    padding: 6px 12px;
    cursor: pointer;
}
.cstm_dropwdown_holder .each_holder i.ti {
    font-size: 16px;
    padding-right: 12px;
}
.ag-theme-alpine .ag-ltr .ag-column-drop-horizontal {
    overflow: auto;
}
.ag-theme-alpine .ag-body-viewport::-webkit-scrollbar,
.userlisting_dropdown::-webkit-scrollbar,
.ag-body-horizontal-scroll-viewport::-webkit-scrollbar,
.thin_horizontal_scrollbar::-webkit-scrollbar ,
.thinscrollbar_holder::-webkit-scrollbar, 
.ag-theme-alpine .ag-ltr .ag-column-drop-horizontal::-webkit-scrollbar {
    width: 8px;
    height: 8px !important;
}
/* .ag-theme-alpine .ag-body-viewport:hover::-webkit-scrollbar {
    width: 8px;
} */
.ag-theme-alpine .ag-body-viewport::-webkit-scrollbar-track,
.userlisting_dropdown::-webkit-scrollbar-track,
.ag-body-horizontal-scroll-viewport::-webkit-scrollbar-track,
.thin_horizontal_scrollbar::-webkit-scrollbar-track,
.thinscrollbar_holder::-webkit-scrollbar-track,
.ag-theme-alpine .ag-ltr .ag-column-drop-horizontal::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 8px;
}
.ag-theme-alpine .ag-body-viewport::-webkit-scrollbar-thumb,
.userlisting_dropdown::-webkit-scrollbar-thumb,
.ag-body-horizontal-scroll-viewport::-webkit-scrollbar-thumb,
.thin_horizontal_scrollbar::-webkit-scrollbar-thumb,
.thinscrollbar_holder::-webkit-scrollbar-thumb,
.ag-theme-alpine .ag-ltr .ag-column-drop-horizontal::-webkit-scrollbar-thumb {
    background-color: #EAECF0;
    border-radius: 8px;
}
.ag-header-row.ag-header-row-column .ag-react-container {
    width: 100%;
}
.ag-header-row.ag-header-row-column .ag-react-container .search_input {
    width: calc(100% - 25px);
}
.ag-header-row.ag-header-row-column .ag-react-container .datepickr_holder {
    display: flex;
    flex-wrap: wrap;
}
.ag-header-row.ag-header-row-column .ag-react-container .datepickr_holder input {
    padding: 6px;
    font-size: 12px;
}
.ag-header-row.ag-header-row-column .ag-react-container .datepickr_holder fieldset {
    border: none;
}
.ag-header-row.ag-header-row-column .ag-react-container .search_input::placeholder,
.ag-header-row.ag-header-row-column .ag-react-container .datepickr_holder input::placeholder {
    font-size: 12px;
    font-weight: 400;
    color: #98a2b3;
    opacity: 1;
}
.sort_icon_holder {
    display: inline-block;
}
.sort_icon_holder .icon_holder {
    display: inline-flex;
    justify-content: center;
    width: 8px;
    font-size: 16px;
    color: #98a2b3;
}
.white_card_holder {
    background: #fff;
    padding: 32px;
    border-radius: 16px;
    box-shadow: 0 4px 44px 0 rgba(0, 0, 0, 0.04);
    margin-bottom: 12px;
}
.profile_section_holder {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
}
.profile_section_holder .left_holder {
    width: 325px;
}
.profile_section_holder .left_holder .white_card_holder {
    padding: 32px 28px;
}
.profile_section_holder .right_holder {
    width: calc(100% - 349px);
}
.profile_dropddown_holder {
    min-width: 76px;
    padding: 12px;
}
.profile_dropddown_holder .anchor_holder,
.profile_dropddown_holder li {
    color: #1D2939;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    padding: 8px;
}
.profile_dropddown_holder .anchor_holder > div,
.profile_dropddown_holder li > div {
    margin: 0;
}
.profile_dropddown_holder .anchor_holder.red_txt {
    color: #F04438;
}
.cstm_tabs_holder {
    display: inline-block;
    background: #f2f4f7;
    border-radius: 8px;
    padding: 8px 12px;
    margin-bottom: 40px;
    min-height: auto;
}
.cstm_tabs_holder .tabs_main_holder {
    min-height: auto;
}
.cstm_tabs_holder .tabs_main_holder .MuiTabs-flexContainer {
    flex-wrap: wrapgrey_table_holder;
}
.cstm_tabs_holder .tabs_main_holder .each_tab_holder {
    min-width: auto;
    max-width: auto;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: #1d2939;
    padding: 8px 16px;
    border-radius: 8px;
    text-transform: capitalize;
    min-height: auto;
    text-decoration: none;
}
.cstm_tabs_holder .tabs_main_holder .each_tab_holder:hover {
    text-decoration: none;
}
.cstm_tabs_holder .tabs_main_holder .each_tab_holder.Mui-selected {
    background: #1d2939;
    color: #fff;
}
.cstm_tabs_holder .tabs_main_holder .MuiTabs-indicator {
    display: none;
}
.form_label_input {
    font-family: "Inter", sans-serif !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    transform: scale(1) !important;
    margin-bottom: 8px !important;
    color: #1d2939 !important;
}
.form_input_holder input {
    font-family: "Inter", sans-serif !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    padding: 10px 16px;
    border-color: #f2f4f7;
}

.form_input_holder ::placeholder {
    font-size: 10px;  
}
.form_input_holder fieldset {
    border-color: #f2f4f7 !important;
}
.form_input_holder.autocomplete_holder input {
    padding: 1px 7px !important;
}
.user_profileImg {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}
.user_profileImg .remove_btn,
.danger_btn {
    font-family: "Inter", sans-serif !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    background: #fef3f2 !important;
    color: #F04438 !important;
    border: 1px solid #F04438 !important;
    padding: 8px 12px;
    border-radius: 4px;
}
.user_profileImg .img_holder {
    width: 127px;
    height: 127px;
    border-radius: 100%;
    background: #fff;
    position: relative;
}
.user_profileImg .img_holder img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    object-fit: contain;
}
.user_profileImg .img_holder .input_btn_holder {
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
}
/* .user_profileImg .img_holder .input_btn_holder .input_file {
    position: absolute;
    z-index: 1;
    width: 40px;
    height: 40px;
    opacity: 0;
    cursor: pointer;
} */
.user_profileImg .img_holder .input_btn_holder .icon_holder,
.user_profileImg .img_holder .input_btn_holder input[type="file"]#avatar-input {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: #fff;
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.08);
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.user_profileImg .img_holder .input_btn_holder input[type="file"]#avatar-input {
    position: absolute;
    z-index: 10;
    opacity: 0;
    cursor: pointer;
}
.user_profileImg .img_holder .input_btn_holder .icon_holder i {
    color: #667085;
    font-size: 18px;
    cursor: pointer;
}
.profile_details_holder .each_holder {
    margin: 16px 0;
}
.profile_details_holder .each_holder .label_holder {
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: #667085;
    margin-bottom: 2px;
}
.profile_details_holder .each_holder .info_holder {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #1d2939;
}
.font_24_heading {
    color: #1d2939;
    font-size: 24px !important;
    font-weight: 600 !important;
    line-height: 32px !important;
    font-family: "Archivo", sans-serif !important;
    margin-bottom: 10px;
}
.primary_btn,
.react_dropzone_holder .dzu-submitButtonContainer .dzu-submitButton {
    background: #562658 !important;
    color: #fff !important;
    font-family: "Inter", sans-serif !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    border: 1px solid #562658 !important;
    padding: 12px 16px !important;
    border-radius: 4px !important;
    text-transform: capitalize !important;
}
.button i.icon {
    font-weight: 600;
    padding-right: 4px;
}
.primary_btn.disabled {
    background: #EAECF0 !important;
    color: #98A2B3 !important;
    pointer-events: none !important;
}
.primary_btn.small_padding {
    padding: 8px 16px !important;
}
.primary_maroon_outlined_btn.small_padding {
    padding: 8px 16px !important;
}
.danger_btn {
    padding: 12px 16px !important;
    border-radius: 4px !important;
    text-transform: capitalize !important;
}
.secondary_btn {
    background: #EAECF0 !important;
    color: #1D2939 !important;
    font-family: "Inter", sans-serif !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    border: 1px solid #D0D5DD !important;
    padding: 12px 16px !important;
    border-radius: 4px !important;
    text-transform: capitalize !important;
}
.secondary_btn.py-4 {
    padding: 4px 16px !important;
}
.cstm_badge_holder {
    display: inline-block;
    background: #F0F6FE;
    color: #3468DC;
    font-family: "Inter", sans-serif !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    border: 1px solid #9BC4F5;
    border-radius: 16px;
    margin: 4px;
    padding: 3px 16px;
    line-height: 16px;
    text-transform: capitalize;
    max-width: 170px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.cstm_black_border_badge {
    display: inline-flex;
    align-items: center;
    padding: 3px 8px;
    border-color: #000;
    background: transparent;
    color: #000;
}
.cstm_badge_holder.enabled,
.cstm_badge_holder.active,
.cstm_badge_holder.low {
    background: #ECFDF3;
    color: #027A48;
    border-color: #6CE9A6;
}
.cstm_badge_holder.high {
    background: #FFFAEB;
    color: #F79009;
    border-color: #FEC84B;
}
.cstm_badge_holder.disabled {
    background: #F9FAFB;
    color: #667085;
    border-color: #D0D5DD;
}
.cstm_black_border_badge .dot_holder {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: #000;
    margin-right: 4px;
}
.cstm_black_border_badge.active .dot_holder {
    background: #12B76A;
}
.cstm_black_border_badge.broken .dot_holder {
    background: #F79009;
}
.cstm_black_border_badge.assigned .dot_holder {
    background: #C366CF;
}
.cstm_black_border_badge.damaged .dot_holder {
    background: #F04438;
}
.cstm_black_border_badge.decom .dot_holder {
    background: #667085;
}
.cstm_black_border_badge.use .dot_holder {
    background: #3468DC;
}
.cstm_black_border_badge.deployed .dot_holder {
    background: #DD6212;
}
.transparent_icon_btn {
    padding: 0 !important;
    min-width: auto !important;
}
.mr-4 {
    margin-right: 0.4rem !important;
}
.mr-12 {
    margin-right: 1.2rem !important;
}
.transparent_icon_btn.back_btn i {
    color: #667085;
    font-size: 24px;
}
.flexbox_holder {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.each_filter_holder {
    display: flex;
    align-items: center;
    margin: 4px 0;
}
.each_filter_holder .cstm_select_formcontrol {
    min-width: 160px;
}
.each_filter_holder .cstm_select_formcontrol.min_width_auto { 
    min-width: 0;
}
.each_filter_holder .cstm_select_formcontrol input,
.each_filter_holder .cstm_select_formcontrol .MuiSelect-select {
    padding: 13px;
}
.each_filter_holder .label_holder,
.normal_label_holder {
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: #1D2939;
    overflow: unset;
    padding-right: 16px;
}
.MuiAutocomplete-popper ul.MuiAutocomplete-listbox,
.autocomplete_holder ul,
.select_opt_holder {
    max-height: 230px;
}
.autocomplete_paper_holder ul {
    margin-top: 50px;
}
.break_list_holder ul li {
    word-break: break-all;
}
.MuiFormHelperText-root {
    font-family: "Inter", sans-serif !important;
    font-size: 12px !important;
    background-color: transparent !important;
}
.assets_catalog_header + .sub_content_holder,
input:not([type='checkbox']), textarea,
.list_holder li,
.MuiAutocomplete-popper ul.MuiAutocomplete-listbox li,
.autocomplete_holder ul li ,
.MuiPaper-root ul li,
.Mui_listview_holder,
.MuiSelect-select,
.normal_txt_holder p,
.MuiAutocomplete-tag {
    font-family: "Inter", sans-serif !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #1D2939 !important;
    height: auto !important;
}
.MuiAutocomplete-tag {
    font-size: 12px !important;
}
.normal_txt_holder > span.MuiCheckbox-root {
    width: 13px;
}
.normal_txt_holder input[type="checkbox"] + svg {
    width: 17px;
    height: 17px;
    opacity: 0.75;
}
.MuiSelect-select {
    align-content: center;
}
.Mui_listview_holder {
    display: flex;
    align-items: center;
    padding: 5px 10px;
}
.cstm_dialog_modal .heading_holder .main_heading {
    font-family: "Archivo", sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: #1D2939;
}
.cstm_dialog_modal .heading_holder .transparent_icon_btn .icon {
    font-size: 20px;
    color: #667085;
}
.cstm_dialog_modal .MuiDialog-paper {
    border-radius: 16px !important;
    /* done due to some scroll problem */
    overflow: hidden; 
}
.cstm_dialog_modal.medium .MuiDialog-paper {
    width: 765px;
    max-width: 765px;
}
.cstm_dialog_modal.small .MuiDialog-paper {
    width: 437px;
    max-width: 437px;
}
.cstm_dialog_modal .action_footer_holder {
    display: flex;
    padding: 0 24px 16px;
}
.cstm_dialog_modal .action_footer_holder.p-24 {
    padding: 0 24px 20px 24px !important;
}
.cstm_dialog_modal .action_footer_holder button {
    width: 100%;
}
.dot_status {
    display: inline-block;
    width: 8px;
    height: 8px;
    background: #12B76A;
    border-radius: 100%;
}
.dot_status.enabled {
    background: #12B76A;
}
.dot_status.disabled {
    background: #667085;
}
.rounded_select_holder {
    border-radius: 16px !important;
}
.rounded_select_holder .MuiSelect-select {
    min-height: auto !important;
    padding: 8px !important;
    padding-right: 16px !important;
}
@keyframes heartbeat
{
  0%
  {
    transform: scale( .75 );
  }
  20%
  {
    transform: scale( 1 );
  }
  40%
  {
    transform: scale( .75 );
  }
  60%
  {
    transform: scale( 1 );
  }
  80%
  {
    transform: scale( .75 );
  }
  100%
  {
    transform: scale( .75 );
  }
}
.warning_content_holder .heading,
.modal_heading {
    font-family: "Archivo", sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: #1D2939;
    margin: 12px 0 8px;
}
.warning_content_holder .sub_content {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #1D2939;
}
.exclamation_icon_holder {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: #ff5050;
    color: #fff;
    animation: heartbeat 2s infinite;
}
.exclamation_icon_holder i {
    font-size: 40px;
    font-weight: 400;
}
.exclamation_icon_holder.red {
    background: #ff5050;
}
.exclamation_icon_holder.yellow {
    background: #ffca2a;
}
.exclamation_icon_holder.green {
    background: #12B76A;
    animation: none;
}
.exclamation_icon_holder.yellow i {
    color: #39484f;
}
.exclamation_icon_holder.yellow i.white {
    color: #fff;
}
.bell_icon_holder {
    width: 40px;
    height: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #1D2939;
    border-radius: 8px;
    padding: 8px;
    cursor: pointer;
    position: relative;
}
.bell_icon_holder .icon {
    font-size: 22px;
    color: #1d2939;
}
.bell_icon_holder.active::after {
    content: '';
    width: 10px;
    height: 10px;
    background: #1d2939;
    border: 2px solid #fff;
    border-radius: 50%;
    position: absolute;
    top: 11px;
    right: 8px;
}
.list_popover_holder li a {
    min-width: 172px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: #667085;
    padding: 8px;
    margin-bottom: 4px;
}
.list_popover_holder li:hover a {
    background: #f2f4f7;
    color: #1d2939;
    text-decoration: none;
}
.list_popover_holder li .count_holder {
    display: inline-block;
    border-radius: 50%;
    min-width: 20px;
    min-height: 20px;
    padding: 5px;
    background: #EAECF0;
    color: #667085;
    text-align: center;
    line-height: 1;
    box-sizing: content-box;
    white-space: nowrap;
}
.list_popover_holder li .count_holder::before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    padding-top: 100%;
    height: 0;
}
.list_popover_holder li .count_holder .number {
    display: inline-block;
    vertical-align: middle;
}
.cstm_dropzone_holder {
    min-width: 600px;
    padding: 40px;
    border: 4px dashed #999;
    text-align: center;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: #667085;
}
.fuse_light_bg {
    background-color: #fff !important;
}
.badge_info_holder {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 9999px;
    background: #EAECF0;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #1D2939;
    padding: 8px 16px;
}
.badge_info_holder img + .text {
    padding-left: 8px;
    padding-right: 24px;
}
.grey_btn {
    min-width: auto !important;
    background-color: #475467 !important;
    border-radius: 4px !important;
    padding: 6px !important;
}
.grey_btn i {
    font-size: 14px;
    color: #fff;
}
.light_grey_btn {
    font-family: "Inter", sans-serif !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    border-radius: 4px !important;
    padding: 8px 16px !important;
    background-color: #EAECF0 !important;
    color: #1D2939 !important;
    border: 1px solid #D0D5DD !important;
    text-transform: capitalize !important;
}
.table_responsive {
    width: 100%;
    overflow: auto;
}
.table_responsive table {
    width: 100%;
    font-family: "Inter", sans-serif;
}
.cstm_table_holder table {
    background: #fff;
}
.cstm_table_holder table thead tr th, 
.conversion_holder .bottom_holder .content_holder table thead tr th {
    text-align: left;
    font-weight: 600;
    font-size: 14px;
    background: #fcf6fd;
    color: #562658;
    border: 1px solid #EAECF0;
    padding: 16px 24px;
}
.cstm_table_holder table tbody tr td,
.conversion_holder .bottom_holder .content_holder table tbody tr td {
    font-family: "Inter", sans-serif !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    border: 1px solid #EAECF0;
}
.conversion_holder .bottom_holder .content_holder table tbody tr td {
    padding: 16px 24px;
}
.cstm_table_holder table tbody tr td .MuiSelect-select {
    font-family: "Inter", sans-serif !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    padding-left: 24px;
}
.cstm_table_holder table tbody tr td:nth-child(1) {
    padding: 0 24px;
}
.cstm_table_holder table tbody tr:nth-child(even) td {
    background: #f9fafb;
}
.cstm_table_holder table tbody tr td .select_holder fieldset {
    border: none;
}
.checkbox_holder {
    padding-left: 8px;
}
.checkbox_holder .MuiFormControlLabel-label {
    font-family: "Inter", sans-serif !important;
    font-weight: 500 !important;
    font-size: 12px !important;
}
.react_dropzone_holder {
    border: 1px dashed #D0D5DD;
    border-radius: 8px;
    text-align: center;
    position: relative;
    padding-top: 20px;
}
.react_dropzone_holder .dzu-dropzone {
    min-height: 150px;
    border: none;
    z-index: 100;
}
.react_dropzone_holder .dzu-dropzone .dzu-inputLabel {
    padding-bottom: 100px;
}
.react_dropzone_holder.blank .outsource_holder {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
}
.react_dropzone_holder .or_holder {
    font-family: "Inter", sans-serif !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    color: #98A2B3;
    position: relative;
    margin: 20px 0;
}
.react_dropzone_holder .or_holder.after_or {
    display: inline-block;
}
.react_dropzone_holder .or_holder::before {
    content: '';
    width: 80px;
    height: 1px;
    background: #D0D5DD;
    position: absolute;
    left: -60px;
    top: 50%;
    transform: translateY(-50%);
}
.react_dropzone_holder .or_holder::after {
    content: '';
    width: 80px;
    height: 1px;
    background: #D0D5DD;
    position: absolute;
    right: -60px;
    top: 50%;
    transform: translateY(-50%);
}
.react_dropzone_holder .or_holder.after_or::before {
    left: -100px;
}
.react_dropzone_holder .or_holder.after_or::after {
    right: -100px;
}
.react_dropzone_holder .image {
    margin: 0 auto;
}
.react_dropzone_holder .loading_holder {
    text-align: center;
    margin-bottom: 50px;
}
.react_dropzone_holder .loading_holder img {
    margin: 0 auto;
    width: 138px;
}
.react_dropzone_holder .loading_holder .text_holder {
    color: #0B0B0B;
    font-family: "Inter", sans-serif !important;
    font-weight: 500 !important;
    font-size: 14px !important;
}
/* .react_dropzone_holder.filled .image, */
/* .react_dropzone_holder.filled .outsource_holder, */
.react_dropzone_holder.blank .temp_holder,
.react_dropzone_holder.filled .temp_holder .or_holder {
    display: none;
}
.react_dropzone_holder.filled .outsource_holder .or_holder + button {
    cursor: not-allowed;
}
.react_dropzone_holder.blank .dzu-inputLabel,
.react_dropzone_holder.filled .heading {
    display: block;
    color: #0B0B0B;
    font-family: "Inter", sans-serif !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    margin-top: 12px;
}

.react_dropzone_holder.filled .headingDisable {
    color: #98A2B3;
}

.react_dropzone_holder .dzu-dropzone {
    width: calc(100% + 20px);
}
.react_dropzone_holder.filled .dzu-inputLabelWithFiles {
    font-family: "Inter", sans-serif !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    border-radius: 4px !important;
    padding: 8px 16px !important;
    background-color: #EAECF0 !important;
    color: #1D2939 !important;
    border: 1px solid #D0D5DD !important;
    text-transform: capitalize !important;
    margin: 0 auto;
}
.react_dropzone_holder.blank .preview_holder {
    display: none;
}
.react_dropzone_holder.filled .preview_holder {
    width: 97%;
    margin: 0 auto 20px;
    display: block;
    text-align: left;
    padding: 16px;
    border: 1px solid #98A2B3;
    border-radius: 12px;
}
.react_dropzone_holder.filled .preview_holder .preview_span_holder {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: #667085;
}
.react_dropzone_holder.filled .preview_holder .preview_span_holder .info_outer_holder {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 8px;
}
.react_dropzone_holder.filled .preview_holder .preview_span_holder .info_outer_holder .info_holder {
    width: calc(100% - 30px);
}
.react_dropzone_holder.filled .preview_holder .preview_span_holder .info_outer_holder .info_holder .inner_holder span {
    display: inline-block;
    position: relative;
}
.react_dropzone_holder.filled .preview_holder .preview_span_holder .info_outer_holder .info_holder .inner_holder span:not(:first-child) {
    padding-left: 15px;
}
.react_dropzone_holder.filled .preview_holder .preview_span_holder .info_outer_holder .info_holder .inner_holder span:not(:first-child)::before {
    content: '';
    width: 4px;
    height: 4px;
    background: #667085;
    border-radius: 100%;
    position: absolute;
    left: 6px;
    top: 50%;
    transform: translateY(-50%);
}
.react_dropzone_holder.filled .preview_holder .preview_span_holder .info_outer_holder .icon_holder i {
    font-size: 24px;
    color: #F04438;
    cursor: pointer;
}
.react_dropzone_holder.filled .preview_holder .preview_span_holder .info_outer_holder .info_holder .heading {
    font-family: "Inter", sans-serif !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    color: #000;
    margin: 0;
}
.react_dropzone_holder.filled .preview_holder .preview_span_holder .progressbar_holder {
    width: 100%;
    height: 8px;
    border-radius: 9999px;
    background: #D0D5DD;
}
.react_dropzone_holder.filled .preview_holder .preview_span_holder .progressbar_holder .progress {
    border-radius: 9999px;
    background: #2B54CA;
}
.react_dropzone_holder.filled .preview_holder .dzu-previewContainer {
    display: block;
    padding: 5px;
}
.react_dropzone_holder.filled .preview_holder .dzu-previewContainer .dzu-previewStatusContainer progress {
    width: 100%;
}
.react_dropzone_holder .preview_holder .dzu-previewContainer .dzu-previewButton {
    filter: invert(21%) sepia(100%) saturate(7414%) hue-rotate(359deg) brightness(94%) contrast(117%)
}
progress::-webkit-progress-value { background: #2B54CA; }
progress::-moz-progress-bar { background: #2B54CA; }
progress { color: #2B54CA !important; }
.white_card_holder {
    background: #fff;
    border-radius: 12px;
    padding: 24px;
}
div.ag-theme-alpine div.ag-row .ag-cell.cell_danger {
    background: #FFFAFA;
    color: #B42318 !important;
}
.cstm_dialog_modal .each_list_holder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    border: 1px solid #EAECF0;
    border-radius: 12px;
    margin-top: 16px;
    cursor: pointer;
}
.cstm_dialog_modal .each_list_holder .left_holder {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.cstm_dialog_modal .each_list_holder .left_holder .badge_container {
    min-width: 34px;
    min-height: 34px;
    width: 34px;
    height: 34px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    margin-right: 8px;
}
.cstm_dialog_modal .each_list_holder .left_holder .badge_container.success_bg {
    background: #ECFDF3;
}
.cstm_dialog_modal .each_list_holder .left_holder .badge_container.danger_bg {
    background: #FEF3F2;
}
.cstm_dialog_modal .each_list_holder .left_holder .middle_container {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 13px;
    color: #475467;
}
.cstm_dialog_modal .each_list_holder .left_holder .middle_container span.success_text {
    font-size: 16px;
    font-weight: 600;
    color: #12B76A;
    margin-right: 4px;
}
.cstm_dialog_modal .each_list_holder .left_holder .middle_container span.danger_text {
    font-size: 16px;
    font-weight: 600;
    color: #F04438;
    margin-right: 4px;
}
.cstm_dialog_modal .each_list_holder .download_container {
    min-width: 32px;
    min-height: 32px;
    width: 32px;
    height: 32px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: #EAECF0;
    border-radius: 8px;
}
.cstm_dialog_modal .each_list_holder .download_container i {
    font-size: 16px;
    color: #475467;
}
.maxmin_btn {
    min-width: auto !important;
    width: 32px;
    height: 32px;
    border-radius: 100% !important;
    background: #fff !important;
    border: 1px solid #562658 !important;
    position: absolute !important;
    bottom: -8px;
    left: 53%;
    transform: translateX(-53%);
}
.maxmin_btn i {
    font-size: 16px;
    color: #562658 !important;
}
.zoomed_dialog_holder .MuiDialog-paper.MuiDialog-paperWidthXl {
    overflow: hidden;
    padding-bottom: 25px;
}
.zoomed_dialog_holder .maxmin_btn {
    bottom: 6px;
    left: 50%;
    transform: translateX(-50%);
}
.each_grey_border_holder {
    display: inline-block;
    border: 1px solid #EAECF0;
    border-radius: 8px;
    padding: 12px 20px;
    margin-bottom: 15px;
    margin-right: 15px;
}
.each_grey_border_holder .light_heading {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: #667085;
}
.each_grey_border_holder .bottom_holder {
    display: inline-flex;
    align-items: center;
}
.each_grey_border_holder .bottom_holder .deep_heading,
.conversion_holder .name_holder,
.medium_heading {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #1D2939;
}
.each_grey_border_holder .bottom_holder i {
    font-size: 20px;
    padding-left: 5px;
}
.conversion_holder {
    width: 100%;
    padding: 16px 28px;
    border: 1px solid #EAECF0;
    border-radius: 16px;
    margin-top: 12px;
}
.conversion_holder.agent_holder {
    border-top-left-radius: 0;
}
.conversion_holder.user_holder {
    border-top-right-radius: 0;
}
.conversion_holder .top_holder {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}
.conversion_holder .top_holder .left_holder {
    display: inline-flex;
    align-items: center;
}
.conversion_holder .top_holder .left_holder .rounded_img_holder,
.rounded_img_holder {
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background: #ccc;
    margin-right: 8px;
}
.conversion_holder .top_holder .left_holder .rounded_img_holder img,
.rounded_img_holder img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 100%;
}
.conversion_holder .top_holder .left_holder .name_holder {
    display: inline-block;
}
.conversion_holder .top_holder .left_holder .badge_holder {
    display: inline-block;
    background: #E9E9F6;
    color: #705D95;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    padding: 3px 8px;
    border-radius: 9999px;
    margin-left: 4px;
}
.conversion_holder .top_holder .left_holder .information_holder {
    position: relative;
}
.conversion_holder .top_holder .left_holder .information_holder .upper_holder {
    margin-bottom: 4px;
}
.conversion_holder .top_holder .left_holder .information_holder .lower_holder {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
}
.conversion_holder .top_holder .left_holder .information_holder .lower_holder .each_info {
    font-family: "Inter", sans-serif;
    font-size: 12px;
    color: #1D2939;
    align-content: center;
}
.conversion_holder .top_holder .left_holder .information_holder .lower_holder .each_info:first-child {
    padding-right: 8px;
}
.conversion_holder .top_holder .left_holder .information_holder .lower_holder .each_info:not(:first-child) {
    padding: 0 8px;
    border-left: 1px solid #98A2B3;
}
.conversion_holder .top_holder .left_holder .information_holder .lower_holder .each_info .label_holder {
    color: #667085;
    padding-right: 4px;
}
.conversion_holder .top_holder .left_holder .information_holder .lower_holder .each_info .num_badge {
    display: inline-block;
    background: #EAECF0;
    color: #1d2939;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-weight: 500;
    border-radius: 4px;
    padding: 4px 8px;
    margin-left: 4px;
    cursor: pointer;
}
.conversion_holder .top_holder .left_holder .information_holder .user_dropdown_holder {
    position: absolute;
    /* right: 0; */
    top: 100%;
    padding: 10px;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 4px 40px 0 rgba(0, 0, 0, 0.08);
    z-index: 1;
    display: none;
}
.conversion_holder .top_holder .left_holder .information_holder .user_dropdown_holder.show {
    display: block;
}
.conversion_holder .top_holder .left_holder .information_holder .user_dropdown_holder .each_user {
    display: flex;
    gap: 4px;
    align-items: center;
    font-family: "Inter", sans-serif;
    font-size: 13px;
    color: #1d2939;
    padding: 8px;
}
.conversion_holder.agent_holder .top_holder .left_holder .badge_holder {
    background: #E9E9F6;
    color: #705D95;
}
.conversion_holder.user_holder .top_holder .left_holder .badge_holder {
    background: #DEEAFB;
    color: #2B54CA;
}
.conversion_holder .bottom_holder .content_holder {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #1D2939;
    margin: 0;
}
.conversion_holder .top_holder .right_holder {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: #667085;
    position: relative;
}
.conversion_holder .top_holder .right_holder .time_holder {
    padding-left: 15px;
    position: relative;
}
.conversion_holder .top_holder .right_holder .time_holder::before {
    content: '';
    width: 6px;
    height: 6px;
    background: #667085;
    border-radius: 100%;
    margin-right: 5px;
    position: absolute;
    left: 5px;
    top: 4px;
}
.grey_table_holder table {
    border-collapse: separate;
    border-spacing: 0px;
    border: 1px solid #EAECF0;
    border-radius: 8px;
}
.grey_table_holder table thead tr th {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 12px;
    color: #1D2939;
    background: #F2F4F7;
    text-align: left;
    border: 1px solid #EAECF0;
    border-top: none;
    padding: 13px 24px;
}
.grey_table_holder table tbody tr td {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: #1D2939;
    border: 1px solid #EAECF0;
    border-bottom: none;
    padding: 7px 24px;
}
.grey_table_holder table thead tr th:nth-child(1) {
    border-left: none;
    border-top-left-radius: 8px;
}
.grey_table_holder table thead tr th:last-child {
    border-right: none;
    border-top-right-radius: 8px;
}
.grey_table_holder table tbody tr:last-child td:nth-child(1) {
    border-left: none;
    border-bottom-left-radius: 8px;
}
.grey_table_holder table tbody tr:last-child td:last-child {
    border-right: none;
    border-bottom-right-radius: 8px;
}
.grey_table_holder table tbody tr td:nth-child(1) {
    border-left: none;
}
.grey_table_holder table tbody tr td:last-child {
    border-right: none;
}
.cstm_textarea {
    width: 100%;
    padding: 8px 16px;
    border: 1px solid #f2f4f7;
    border-radius: 4px;
}
.cstm_dialog_modal .cstm_textarea::placeholder {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #98A2B3;
}
.cstm_dialog_modal .cstm_textarea,
.cstm_label_holder {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #1D2939;
}
.grey_textarea_input {
    width: 100%;
    padding: 12.5px 14px;
    border: 1px solid #F2F4F7;
}
.primary_link_holder {
    color: #562658 !important;
}
.primary_link_holder i {
    font-size: 16px;
    padding-right: 4px;
}
.subject_holder .content_holder,
.MuiMenu-list li {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #1D2939;
}
.cstm_badge_select_holder {
    border-radius: 9999px !important;
}
.cstm_badge_select_holder .MuiSelect-select {
    display: flex;
    padding: 4px 8px;
}
.cstm_badge_select_holder .MuiSelect-select .cstm_black_border_badge_holder {
    margin: 0;
    padding: 1px 0;
    background: transparent;
    color: #000;
    border: none;
}
.cstm_badge_select_holder fieldset {
    border-color: #000;
}
.cstm_black_border_badge_holder {
    display: inline-flex;
    align-items: center;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: #000000;
    border: 1px solid #000;
    border-radius: 9999px;
    padding: 4px 8px;
}
.cstm_black_border_badge_holder .dot_holder {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: #000;
    margin-right: 4px;
}
.cstm_black_border_badge_holder.open .dot_holder {
    background: #12B76A;
}
.cstm_black_border_badge_holder.in_progress .dot_holder {
    background: #F79009;
}
.cstm_black_border_badge_holder.pending .dot_holder {
    background: #C366CF;
}
.cstm_black_border_badge_holder.closed .dot_holder {
    background: #F04438;
}
.no_border_select_holder .MuiSelect-select {
    display: inline-flex;
    align-items: center;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #1D2939;
    padding: 0;
}
.no_border_select_holder fieldset {
    border: none;
}
.MuiDialog-paperFullWidth .mapboxgl-popup {
    max-width: 280px !important;
    will-change: auto;
    transform: none !important;
    flex-direction: initial;
    height: 100%;
    left: 0;
    right: auto;
    z-index: 100;
    overflow: auto;
}
.MuiDialog-paperFullWidth .mapboxgl-popup .mapboxgl-popup-content {
    min-width: 280px;
    max-height: 100%;
    overflow: auto;
}
.MuiDialog-paperFullWidth .mapboxgl-popup .mapboxgl-popup-content h5 {
    width: 228px;
    font-size: 14px;
}
.MuiDialog-paperFullWidth .mapboxgl-popup-content input[type="checkbox"]:checked + span.icon + label {
    margin: 12px 0;
}
.MuiDialog-paperFullWidth .mapboxgl-popup .mapboxgl-popup-content > div ul li {
    padding: 6px 8px;
    padding-left: 18px;
    font-size: 12px;
}
.MuiDialog-paperFullWidth .mapboxgl-popup .mapboxgl-popup-content .mapboxgl-popup-close-button {
    display: none;
}
.overflow_hidden {
    overflow: hidden !important;
}
.overflow_auto {
    overflow: auto !important;
}
.scrollable_form_holder {
    max-height: calc(100vh - 130px);
    overflow: auto;
}
.scrollable_form_holder.submission_form .sidebar_btn_holder {
    width: 100%;
    background: #fff;
    padding: 14px;
    position: absolute;
    bottom: 0;
    left: 0;
}
.list_holder li {
    padding: 5px 0;
}
.location_list_holder li {
    position: relative;
    padding-left: 18px;
}
.location_list_holder li::before {
    content: '\eae8';
    font-family: "tabler-icons" !important;
    font-style: normal;
    font-weight: normal;
    position: absolute;
    left: 0;
    top: 6px;
}
.value_input input {
    padding: 18.5px 14px !important;
}
.main_autocomplete + div {
    top: 100%;
}
.inner_details_section .assetDetails_imgbox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 350px;
    max-width: calc(100% - 580px);
    height: 300px;
    margin: 0 auto;
    background: #fff;
    border: 1px solid #E9E9F6;
    border-radius: 16px;
}
.inner_details_section .assetDetails_imgbox .no_img_info {
    display: inline-block;
    text-align: center;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 30px;
    color: #b3b3b3;
}
.inner_details_section .assetDetails_imgbox img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border: 1px solid #E9E9F6;
    border-radius: 16px;
}
.inner_details_section .left_holder {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 360px);
}
.inner_details_section .left_holder.img_included_holder .content_holder {
    min-width: 580px;
    width: calc(100% - 350px);
    padding: 0 15px !important;
}
.inner_details_section .left_holder .content_holder {
    width: 100%;
    align-content: center;
    padding-right: 25px;
}
.inner_details_section .left_holder .content_holder.align-top {
    align-content: normal;
}
.inner_details_section .left_holder .content_holder .status_badge_holder {
    display: inline-block;
    padding: 1px 8px;
    border: 1px solid #000;
    border-radius: 16px;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: #000;
    margin: 3px 6px 3px 0;
}
.inner_details_section .left_holder .content_holder .status_badge_holder .dot_holder {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    margin-right: 4px;
}
.inner_details_section .left_holder .content_holder .name_loc_info_holder {
    display: flex;
    flex-wrap: wrap;
    padding: 28px 0;
}
.inner_details_section .left_holder .content_holder .name_loc_info_holder .share_btn {
    min-width: 36px;
    min-height: 36px;
    max-width: 36px;
    max-height: 36px;
    background-color: #F2F4F7 !important;
    box-shadow: none !important;
    border-radius: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 4px;
    cursor: pointer;
}
.inner_details_section .left_holder .content_holder .name_loc_info_holder .share_btn i {
    font-size: 20px;
    color: #1D2939;
}
.inner_details_section .left_holder .content_holder .name_loc_info_holder .main_page_heading {
    display: inline-block;
    max-width: 420px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 6px;
}

.inner_details_section .left_holder .content_holder .name_loc_info_holder .asset_main_page_heading {
    display: inline-block;
    font-family: "Archivo", sans-serif !important;
    font-size: 24px !important;
    font-weight: 600 !important;
    color: #1d2939;
    padding-right: 6px;
}
.inner_details_section .left_holder .content_holder .name_loc_info_holder .orange_badge_holder {
    display: inline-block;
    max-width: 160px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background: #FFEDE1;
    color: #DD6212;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 12px;
    border: 1px solid #DD6212;
    border-radius: 16px;
    padding: 2px 16px;
}
.inner_details_section .left_holder .content_holder .name_loc_info_holder .asset_address_text {
    width: 100%;
    display: inline-flex;
    /* align-items: center; */
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: #1D2939;
    margin: 4px 0;
}
.inner_details_section .left_holder .content_holder .name_loc_info_holder .desc_text {
    width: 100%;
    display: inline-flex;
    align-items: center;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #667085;
}
.inner_details_section .left_holder .content_holder .name_loc_info_holder .asset_address_text .ti {
    display: inline-block;
    font-size: 16px;
    margin-right: 2px;
}
.inner_details_section .left_holder .content_holder .infoStat_card_holder {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}
.inner_details_section .left_holder .content_holder .infoStat_card_holder .each_card {
    font-family: "Inter", sans-serif;
    padding: 12px 20px;
    border: 1px solid #EAECF0;
    border-radius: 8px;
}
.inner_details_section .left_holder .content_holder .infoStat_card_holder .each_card .label_holder {
    font-size: 12px;
    font-weight: 400;
    color: #667085;
    margin-bottom: 0;
}
.inner_details_section .left_holder .content_holder .infoStat_card_holder .each_card .name_holder {
    font-size: 14px;
    font-weight: 500;
    color: #1D2939;
    margin-bottom: 0;
}
.inner_details_section .map_location_holder {
    max-width: 360px;
    max-height: 300px;
    margin-left: auto;
    padding: 20px;
    border: 1px solid #EAECF0;
    border-radius: 16px;
    background: #fff;
}
.inner_details_section .map_location_holder .asset_address_text {
    display: inline-flex;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: #1D2939;
    margin-bottom: 20px;
}
.normal_text {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 12px !important;
    color: #1D2939;
    margin-bottom: 0; 
}

.cstm_ul_holder_outer .normal_text_outer {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 14px !important;
    color: #0f50a4;
    margin-bottom: 0;
    margin-top: 8px;
}

.cstm_ul_holder_inner {
    margin-left: 30px;
}
.cstm_ul_holder_inner li{
    list-style-type: disc;
}
.cstm_ul_holder_inner .normal_text_inner {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 12px !important;
    color: #1D2939;
    margin-bottom: 0;
}

.back_btn_icon {
    display: inline-block;
    margin-bottom: 12px;
}
.back_btn_icon:hover {
    text-decoration: none !important;
}
.back_btn_icon i {
    font-size: 24px;
    color: #667085;
}
.cstm_aggridtable_holder {
    width: 100%;
    overflow: auto;
}
.cstm_aggridtable_holder table {
    width: 100%;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    color: #1D2939;
    border-collapse: separate;
    border-spacing: 0;
}
.cstm_aggridtable_holder table thead tr th {
    text-align: left;
    background: #F2F4F7;
    font-weight: 600;
    padding: 14px;
    border: 1px solid #EAECF0;
}
.cstm_aggridtable_holder table thead tr th:first-child {
    border-top-left-radius: 8px;
}
.cstm_aggridtable_holder table thead tr th:last-child {
    border-top-right-radius: 8px;
}
.cstm_aggridtable_holder table tbody tr td {
    text-align: left;
    background: transparent;
    font-weight: 500;
    padding: 10px 14px;
    border: 1px solid #EAECF0;
    border-right: 1px solid #F2F4F7;
}
.cstm_aggridtable_holder table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 8px;
}
.cstm_aggridtable_holder table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 8px;
}
.no_data_holder {
    font-family: "Inter", sans-serif;
    text-align: center;
}
.no_data_holder img {
    margin: 0 auto;
}
.no_data_holder .bold_txt {
    font-size: 18px;
    font-weight: 600;
    color: #98A2B3;
    margin: 4px 0;
}
.no_data_holder .normal_txt {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #475467;
}
.top_bottom_btn {
    position: fixed;
    right: 50px;
    bottom: 15px;
    background: #F8ECFB;
    color: #562658;
    border: 1px solid #562658;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    animation: bounce 2s infinite;
    cursor: pointer;
}
@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
}

.singleAttachment {
    width: 100%;
    border: 1px solid #D0D5DD;
    border-radius: 4px;
    padding: 4px 8px;
    margin: 4px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.singleAttachment .ti-xbox-x {
    cursor: pointer;
    color: #ff0000;
}


.orange_badge_holder {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #fffefe;
    color: #F9B84B;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 10px;
    border: 1px solid #F9B84B;
    border-radius: 16px;
    padding: 1px 10px;
}
.semibold_txt {
    font-weight: 600;
}
.notes_holder {
    font-family: "Inter", sans-serif;
    color: #1D2939;
}
.notes_holder .heading {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
}
.notes_holder .content_holder {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
}
.user_profile_holder {
    display: flex;
    background: #fff;
}
.user_profile_holder .left_holder {
    width: 330px;
    background: #FCFCFD;
    border-right: 1px solid #EAECF0;
}
.user_profile_holder .left_holder .top_user_info_holder {
    padding: 24px;
    border-bottom: 1px solid #EAECF0;
}
.user_profile_holder .left_holder .top_user_info_holder .image_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}
.user_profile_holder .left_holder .top_user_info_holder .image_container .profile_image {
    width: 107px;
    height: 107px;
    border-radius: 100%;
    object-fit: contain;
}
.user_profile_holder .left_holder .top_user_info_holder .image_container .id_holder {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: #FFEDE1;
    color: #DD6212;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 12px;
    padding: 0 8px;
    border: 1px solid #DD6212;
    border-radius: 16px;
}
.user_profile_holder .left_holder .top_user_info_holder .user_name_holder {
    font-family: "Archivo", sans-serif;
    font-size: 20px;
    font-weight: 600;
    color: #1d2939;
    margin: 8px 0;
}
.user_profile_holder .left_holder .top_user_info_holder .user_badge_holder {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 20px;
}
.user_profile_holder .left_holder .top_user_info_holder .user_badge_holder .each_badge {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 12px;
    border-radius: 9999px;
    min-width: 50px;
    text-align: center;
    padding: 6px 10px;
}
.user_profile_holder .left_holder .top_user_info_holder .user_badge_holder .each_badge.job_holder {
    background: #EAECF0;
    color: #1d2939;
}
.user_profile_holder .left_holder .top_user_info_holder .user_badge_holder .each_badge.role_holder {
    background: #FEF0C7;
    color: #DC6803;
}
.user_profile_holder .left_holder .bottom_user_info_holder {
    padding: 32px 24px;
}
.user_profile_holder .left_holder .bottom_user_info_holder .each_info_holder {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}
.user_profile_holder .left_holder .bottom_user_info_holder .each_info_holder:not(:last-child) {
    margin-bottom: 24px;
}
.user_profile_holder .left_holder .bottom_user_info_holder .each_info_holder .icon {
    font-size: 16px;
    color: #667085;
}
.user_profile_holder .left_holder .bottom_user_info_holder .each_info_holder .information_holder {
    width: calc(100% - 30px);
}
.user_profile_holder .left_holder .bottom_user_info_holder .each_info_holder .information_holder .label_holder {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: #667085;
    margin-bottom: 4px;
}
.user_profile_holder .left_holder .bottom_user_info_holder .each_info_holder .information_holder .info_holder {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #1D2939;
}
.user_profile_holder .right_holder {
    width: calc(100% - 330px);
    padding: 28px;
}

.cstm_input_container input {
    padding: 5px 10px;
    width: 100%;
    border: 1px solid #EAECF0 !important;
    border-radius: 8px;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 12px;
}
.cstm_input_container input::placeholder {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: #98A2B3;
}
.login_holder .icon_holder span.material-icons {
    display: inline-flex;
}
.small_select_holder .assets_listFiled > .MuiSelect-select {
    padding: 9px 14px;
    min-height: auto;
}
.two_columns_holder {
    display: flex;
    min-height: calc(100vh - 78px);
}
.two_columns_holder .left_holder {
    width: 247px;
    border-right: 1px solid #EAECF0;
}
.two_columns_holder .right_content_holder {
    width: calc(100% - 247px);
    padding: 28px;
    box-sizing: border-box;
}
.checkbox_form_holder label.MuiFormControlLabel-labelPlacementStart {
    margin: 0;
    padding: 12px 16px;
    border-bottom: 1px solid #EAECF0;
}
.checkbox_form_holder label.MuiFormControlLabel-labelPlacementStart .MuiFormControlLabel-label {
    width: 100%;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #1d2939;
}
.connector_outer_holder .left_holder .each_block {
    padding: 16px;
}
.connector_outer_holder .left_holder .each_block.search_holder {
    padding-bottom: 4px;
}
.connector_outer_holder .left_holder .heading_holder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #EAECF0;
    padding: 12px 16px;
}
.connector_outer_holder .left_holder .heading_holder .left_holder {
    display: inline-flex;
    align-items: center;
}
.connector_outer_holder .left_holder .heading_holder .left_holder h6 {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #1d2939;
    margin-left: 4px;
}
.connector_outer_holder .left_holder .heading_holder .right_holder {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: #562658;
    text-decoration: underline;
    white-space: nowrap;
}
.connectors_block_holder {
    display: flex;
    /* grid-template-columns: repeat(auto-fit, minmax(340px, 1fr)); */
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px 0;
}
.connectors_block_holder .each_connector_block {
    min-width: 340px;
    max-width: calc(33.33% - 14px);
    background: #fff;
    padding: 32px;
    border: 1px solid #EAECF0;
    border-radius: 16px;
    box-shadow: 0px 24px 48px -12px rgba(16,24,40,0.04);
}
.connectors_block_holder .each_connector_block.broken {
    background: #FEF3F2;
    border-color: #FEE4E2;
}
.connectors_block_holder .each_connector_block .top_holder {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}
.connectors_block_holder .each_connector_block .top_holder .updated_status {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: #475467;
}
.connectors_block_holder .each_connector_block .top_holder .logo_holder {
    max-width: 78px;
}
.connectors_block_holder .each_connector_block .heading_holder {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
.connectors_block_holder .each_connector_block .heading_holder .heading {
    display: inline-block;
    font-family: "Archivo", sans-serif;
    font-weight: 500;
    font-size: 20px;
    color: #1d2939;
}
.connectors_block_holder .each_connector_block .heading_holder .badge_holder {
    display: inline-block;
    padding: 3px 16px;
    border-radius: 16px;
    border: 1px solid #6CE9A6;
    background: #ccc;
    color: #000;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 12px;
    text-transform: capitalize;
}
.connectors_block_holder .each_connector_block .heading_holder .badge_holder.connected {
    background: #ECFDF3;
    color: #027A48;
    border-color: #6CE9A6;
}
.connectors_block_holder .each_connector_block .heading_holder .badge_holder.broken {
    background: #FEF3F2;
    color: #F04438;
    border-color: #FDA29B;
}
.connectors_block_holder .each_connector_block .heading_holder .badge_holder.not_connected {
    background: #9ea4aa;
    color: #2d3848;
    border-color: #9ea4a9;
}
.connectors_block_holder .each_connector_block .type_holder {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: #475467;
    padding: 6px 0;
}
.connectors_block_holder .each_connector_block .desc_holder {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: #475467;
    /* display: inline-block; */
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 51px;
}
.connectors_block_holder .each_connector_block .bottom_btn_holder {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    height: 32px;
}
.connectors_block_holder .each_connector_block .bottom_btn_holder button {
    height: 100%;
}
.connectors_block_holder .each_connector_block .bottom_btn_holder button.light_grey_btn {
    box-shadow: none;
    min-width: auto !important;
    padding: 8px !important;
    border: none !important;
}
.connectors_block_holder .each_connector_block .bottom_btn_holder button.light_grey_btn svg {
    width: 16px;
    height: 16px;
    font-size: 16px;
}
.connectors_block_holder .each_connector_block .bottom_btn_holder .link_holder,
.cstm_dialog_modal .warning_content_holder .link_holder {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 12px;
    text-decoration: underline;
    color: #30293D;
    cursor: pointer;
}
.connectors_block_holder .each_connector_block .bottom_btn_holder .link_holder.error {
    color: #F04438;
}
.cstm_popover_list_holder {
    padding: 12px;
}
.cstm_popover_list_holder li {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 12px;
    padding: 8px;
    cursor: pointer;
}
.cstm_wizard_holder .test_connection {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 4px;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: #562658;
}
.cstm_wizard_holder .test_connection.disabled {
    color: #98A2B3;
}
.cstm_wizard_holder .test_connection svg {
    width: 16px;
    transform: rotate(45deg);
}
.border_bottom {
    border-bottom: 1px solid #EAECF0;
}
.submain_heading {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: #1D2939;
}
.submain_heading + .sub_content {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #1D2939;
}
.capitalize {
    text-transform: capitalize !important;
}
@keyframes rotate360 {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
}
.loader_animation_holder {
    text-align: center;
}
.loader_animation_holder img {
    animation: rotate360 2s linear infinite;
    margin: 0 auto;
}
.pattern_image_holder {
    height: 172px;
    background-color: #f2f4f7;
    background-image: url(../../src/app/main/connectors/icons/Pattern.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    margin-bottom: 24px;
}
.data_status_tooltip_holder {
    width: 100%;
    text-align: center;
    position: absolute;
    left: 0;
    top: 0;
    padding: 8px;
    background: #1D2939;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-weight: 500;
}
.data_status_tooltip_holder svg {
    width: 20px;
    height: 20px;
}
.data_status_tooltip_holder .underlined_txt {
    color: #FBA8A6;
    text-decoration: underline;
    cursor: pointer;
}
.circular_progressbar_holder {
    display: inline-flex;
    font-family: "Inter", sans-serif;
    font-size: 10px;
    font-weight: 500;
    color: #1D2939;
    position: relative;
}
.circular_progressbar_holder .value_holder {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.uploaded_content_holder {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
}
.uploaded_content_holder > div,
.uploaded_content_holder .attachment_name_holder {
    display: inline-flex;
    padding: 8px 12px;
    border: 1px solid #EAECF0;
    border-radius: 9999px;
    cursor: pointer;
}
.uploaded_content_holder > div a,
.uploaded_content_holder .attachment_name_holder {
    font-family: "Inter", sans-serif !important;
    font-size: 12px;
    font-weight: 500;
    background-color: transparent !important;
    color: #1d2939 !important;
    border-bottom: none !important;
    cursor: pointer;
}





.cstm_multi_select_dropdown {
    position: relative;
    width: 100%;
    font-family: "Inter", sans-serif;
}
.cstm_multi_select_dropdown .cstm_input_outer_holder {
    display: flex;
    flex-wrap: wrap;
    padding: 12px;
    border: 1px solid #EAECF0;
    border-radius: 4px;
    gap: 10px;
}
.cstm_multi_select_dropdown .cstm_input_outer_holder .chip_container {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 5px;
}
.cstm_multi_select_dropdown .cstm_input_outer_holder .chip_container .chip_holder {
    background: #e9eaeb;
    color: #1d2939;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 16px;
    display: inline-flex;
    white-space: nowrap;
    align-items: center;
}
.cstm_multi_select_dropdown .cstm_input_outer_holder .chip_container .chip_holder .close-btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    border-radius: 100%;
    background: #000;
    color: #fff;
    margin-left: 8px;
    cursor: pointer;
}
.cstm_multi_select_dropdown .cstm_input_outer_holder .input_box {
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    text-overflow: ellipsis;
    padding: 0;
    box-sizing: border-box;
}
.cstm_multi_select_dropdown .cstm_dropdown_holder {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-width: 316px;
    max-height: 350px;
    padding: 10px;
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 40px 0 rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    z-index: 10;
}
.cstm_multi_select_dropdown .cstm_dropdown_holder .list_holder {
    max-height: 250px;
    overflow: auto;
}
.cstm_multi_select_dropdown .cstm_dropdown_holder .list_holder li {
    display: flex;
    flex-wrap: wrap;
    padding: 8px 14px;
    cursor: pointer;
}
.cstm_multi_select_dropdown .cstm_dropdown_holder .list_holder li:hover {
    background: #f0f0f0;
}
.cstm_multi_select_dropdown .cstm_dropdown_holder .list_holder li .info_holder .name_holder {
    font-size: 14px;
    font-weight: 500;
    color: #1D2939;
}
.cstm_multi_select_dropdown .cstm_dropdown_holder .list_holder li .info_holder .mail_holder {
    font-size: 12px;
    font-weight: 400;
    color: #666b70;
}
.cstm_multi_select_dropdown .cstm_dropdown_holder .btn_holder {
    display: flex;
    gap: 12px;
    padding: 0 14px;
    margin-top: 16px;
}
.cstm_multi_select_dropdown .cstm_dropdown_holder .btn_holder button {
    width: 100%;
}
.forward_arrow_holder {
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    background: #EAECF0;
    border: 1px solid #d0d5dd;
    border-radius: 4px;
    display: none;
    cursor: pointer;
}
.forward_arrow_holder i {
    width: 16px;
    font-size: 16px;
    color: #475467;
}
.conversion_holder:hover .right_holder .forward_arrow_holder {
    display: inline-flex;
}
.see_more_content_holder {
    display: none;
}
.see_more_content_holder.show, #main_conversation .see_more_btn {
    display: block !important;
}

#main_conversation .see_more_btn {
    background: #e8eaed !important;
    color: #000;
    font-weight: 600;
    padding: 2px 6px;
    border-radius: 4px;
    cursor: pointer;
}
.floating_bulk_holder {
    font-family: "Inter", sans-serif;
    display: flex;
    align-items: center;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 10px;
    background: #000;
    color: #fff;
    padding: 8px 12px;
    border-radius: 8px;
}
.floating_bulk_holder .left_holder {
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    gap: 4px;
    padding-right: 16px;
    margin-right: 16px;
    border-right: 1px solid rgba(255, 255, 255, 0.28);
}
.floating_bulk_holder .left_holder .num_holder {
    min-width: 24px;
    background: rgba(255, 255, 255, 0.16);
    padding: 4px;
    border-radius: 4px;
}
.floating_bulk_holder .left_holder i {
    cursor: pointer;
}
.floating_bulk_holder .btns_holder {
    display: inline-flex;
    gap: 8px;
}
.floating_bulk_holder .btns_holder button {
    padding: 4px 14px !important;
}

@media (max-width: 1360px) {
    .inner_details_section .left_holder {
        width: 100%;
    }
    .inner_details_section .map_location_holder {
        width: 100%;
        max-width: 100%;
        margin-top: 20px;
    }
}
@media (max-width: 991px) {
    .each_cat_card {
        width: 200px;
    }
    .inner_details_section .left_holder .content_holder {
        width: 100%;
        text-align: center;
        padding: 28px 0;
    }
}
@media (max-height: 800px) {
    .MuiDialog-paperFullWidth .mapboxgl-popup .mapboxgl-popup-content {
        max-height: calc(100vh - 110px);
    }
}