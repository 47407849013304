/**
 * Tailwind's utility classes, generated based on your config file.
 */

@import 'tailwindcss/utilities';

.MuiPickersBasePicker-pickerView-1266{
    max-width: 300px !important;
    min-width: 300px !important;
}
