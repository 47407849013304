.mapboxgl-ctrl-group button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mapbox-gl-reset_view::after{
  content: '\f3ae';
  font-family: "tabler-icons" !important;
  font-size: 18px;
  display: inline-flex;
}

