
.ag-floating-filter-button  {
    display: none;
}

.ag-floating-filter-input .ag-input-field input[type="text"] {
    border-bottom-color: transparent;
    font-size: 13px;
    border: 2px solid #b9b9bb;
    border-radius: 5px;
    padding: 5px;
    min-width: 68px;
}

.ag-theme-material .ag-row-hover {
    background-color: #eeeeee
}
