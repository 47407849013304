/**
 * Normalize.css and sTailwind's base styles.
 */

@import-normalize;

@import 'tailwindcss/base';

/**
 * Custom base styles, applied after the tailwind-base classes
 */

html {
    font-size: 62.5%;
    font-family: 'Inter, sans-serif';
    background-color: #121212;
}

body {
    font-size: 13px;
    line-height: 1.4;
    overflow-x: hidden;
    padding: 0;
    margin: 0
}

html, body, #root {
    position: relative;
    margin: 0;
    min-height: 100vh;
}

h1, .h1 {
    font-size: 24px;
}

h2, .h2 {
    font-size: 20px;
}

h3, .h3 {
    font-size: 16px;
}

h4, .h4 {
    font-size: 15px;
}

h5, .h5 {
    font-size: 13px;
}

h6, .h6 {
    font-size: 12px;
}

.ps > .ps__rail-y,
.ps > .ps__rail-x {
    z-index: 99;
}

a[role=button] {
    text-decoration: none;
}

[role="tooltip"] {
    z-index: 9999;
}

/* Medium Devices, Desktops Only */
@media only screen and (min-width: 992px) {
    ::-webkit-scrollbar {
        width: 18px;
        height: 18px;
        background-color: rgba(0, 0, 0, 0);
    }

    ::-webkit-scrollbar:hover {
        width: 18px;
        height: 18px;
        background-color: rgba(0, 0, 0, 0.06);
    }

    ::-webkit-scrollbar-thumb {
        border: 2px solid transparent;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:active {
        border-radius: 10px;
    }
}

form label {
    z-index: 99;
}

body.no-animate *,
body.no-animate *::before,
body.no-animate *::after {
    transition: none !important;
    animation: none !important;
}

button:focus {
    outline: none;
}

@keyframes autofill {
    0%,100% {
        background: transparent;
    }
}

input:-webkit-autofill {
    animation-delay: 1s;
    animation-name: autofill!important;
    animation-fill-mode: both;
    -webkit-box-shadow:0 0 0 100px transparent inset!important;
}

:focus {
    outline-color: transparent;
}

            
.arrow-pointer {
    height: 80px;
    background: #F9B84B;
    position: relative;
    color: #1E1F23;
    text-align: center;
    padding: 10px 0px 10px 25px;
    cursor: pointer;
    margin-left: 22px;
  }

  .arrow-pointer::before {
    content: '';
    position: absolute;
    right: -20px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 20px solid #F9B84B;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    cursor: pointer;
  }

  .arrow-pointer::after {
    content: '';
    position: absolute;
    left: 0; bottom: 0; width: 0; height: 0;
    border-left: 20px solid #ffffff;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
  }

.btn {
   /* just for this demo. */
   height: 45px;
   position: relative;
   margin: 2px;
}

/* secondary arrow button color schemes */
.btn-secondary {
    background-color: #F9B84B;
}

.btn-secondary:hover {
    background-color: #f9ae4b;
}

.btn-secondary:active {
    background-color: #f9a54b;
}

/* nutral arrow button color schemes */
.btn-nutral {
    background-color: #a3a3a3;
}

.btn-nutral:hover {
    background-color: #949494;
}

.btn-nutral:active {
    background-color: #878787;
}

/* success arrow button color schemes */
.btn-success {
    background-color: #00db8b;
}

.btn-success:hover {
    background-color: #00d185;
}

.btn-success:active {
    background-color: #02c47e;
}

/* warning arrow button color schemes */
.btn-warning {
    background-color: #fcf803;
}

.btn-warning:hover {
    background-color: #fcf003;
}

.btn-warning:active {
    background-color: #fce703;
}

/* info arrow button color schemes */
.btn-info {
    background-color: #03dffc;
}

.btn-info:hover {
    background-color: #03c6fc;
}

.btn-info:active {
    background-color: #03bafc;
}

/* danger arrow button color schemes */
.btn-danger {
    background-color: #ff5100;
}

.btn-danger:hover {
    background-color: #f04c00;
}

.btn-danger:active {
    background-color: #e64900;
}

.btn-arrow-right,
.btn-arrow-left {
   position: relative;
   padding-left: 36px;
   padding-right: 36px;
}

.btn-arrow-right {
   padding-left: 36px;
   padding-right: 36px;
}

.btn-arrow-left {
   padding-right: 36px;
   padding-left: 36px;
}

.btn-arrow-right:before,
.btn-arrow-right:after,
.btn-arrow-left:before,
.btn-arrow-left:after{
   /* make two squares (before and after), looking similar to the button */
   
   content: "";
   position: absolute;
   top: 6px;
   /* move it down because of rounded corners */
   
   width: 32px;
   /* same as height */
   
   height: 32px;
   /* button_outer_height / sqrt(2) */
   
   border: inherit;
   /* use parent border */
   
   border-left-color: transparent;
   /* hide left border */
   
   border-bottom-color: transparent;
   /* hide bottom border */
   
   border-radius: 0px 4px 0px 0px;
   /* round arrow corner, the shorthand property doesn't accept "inherit" so it is set to 4px */
   
   -webkit-border-radius: 0px 4px 0px 0px;
   -moz-border-radius: 0px 4px 0px 0px;
}

.btn-arrow-right:before,
.btn-arrow-right:after {
   transform: rotate(45deg);
   /* rotate right arrow squares 45 deg to point right */
   
   -webkit-transform: rotate(45deg);
   -moz-transform: rotate(45deg);
   -o-transform: rotate(45deg);
   -ms-transform: rotate(45deg);
}

.btn-arrow-left:before,
.btn-arrow-left:after {
   transform: rotate(225deg);
   /* rotate left arrow squares 225 deg to point left */
   
   -webkit-transform: rotate(225deg);
   -moz-transform: rotate(225deg);
   -o-transform: rotate(225deg);
   -ms-transform: rotate(225deg);
}

.btn-arrow-right:before,
.btn-arrow-left:before {
   /* align the "before" square to the left */
   
   left: -16px;
}

.btn-arrow-right:after,
.btn-arrow-left:after {
   /* align the "after" square to the right */
   
   right: -16px;
}

.btn-arrow-right:after,
.btn-arrow-left:before {
   /* bring arrow pointers to front */
   
   background: inherit;
   /* use parent background */
   z-index: 2;
}

.btn-arrow-right:before,
.btn-arrow-left:after {
   /* hide arrow tails background */
   
   background-color: white;
}
      
  
