/**
Basic Table Styles
 */
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

table.simple {
  width: 100%;
  border: none;
  border-spacing: 0;
  text-align: left;
}

table.simple thead tr th {
  padding: 16px 8px;
  font-weight: 500;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  white-space: nowrap;
}

table.simple thead tr th:first-child {
  padding-left: 24px;
}

table.simple thead tr th:last-child {
  padding-right: 24px;
}

table.simple tbody tr td {
  padding: 12px 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

table.simple tbody tr td:first-child {
  padding-left: 24px;
}

table.simple tbody tr td:last-child {
  padding-right: 24px;
}

table.simple tbody tr:last-child td {
  border-bottom: none;
}

table.simple.clickable tbody tr {
  cursor: pointer;
}

table.simple.clickable tbody tr:hover {
  background: rgba(0, 0, 0, 0.03);
}

table.simple.borderless {
  border: none;
}

table.simple.borderless tbody tr td {
  border: none;
}

table.simple.borderless thead tr th {
  border: none;
}

.catalog_card {
  min-height: 168px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: #DFD3FF;
  color: #1D2939;
  border-radius: 12px;
  padding: 12px 16px;
  position: relative;
  /* height: calc(100% - 18px); */
}

.catalog_cardDetails {
  margin-top: 20px;
}

.dashboard_cardDetails p {
  font-weight: 500;
  font-size: 12px;
  Line-height: 16px;
  color: #1D2939;
  font-family: "Inter", sans-serif !important;
  margin-top: 16px;
}

.dashboard_cardDetails h5 {
  font-weight: 700;
  font-size: 20px;
  Line-height: 28px;
  color: #1D2939;
  font-family: "Archivo", sans-serif !important;
  margin-top: 2px;
}

.catalog_cardDetails p {
  font-weight: 500;
  font-size: 12px;
  Line-height: 16px;
  color: #1D2939;
  font-family: "Inter", sans-serif !important;
}

.catalog_cardDetails h5 {
  font-weight: 600;
  font-size: 16px;
  Line-height: 24px;
  color: #1D2939;
  font-family: "Inter", sans-serif !important;
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.category_text {
  font-weight: 600 !important;
  font-size: 18px !important;
  Line-height: 28px !important;
  color: #1D2939 !important;
  font-family: "Archivo", sans-serif !important;
}

.assets_viewText {
  color: #1D2939 !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  Line-height: 16px !important;
  font-family: "Inter", sans-serif !important;
}

.catalog_cardTwo {
  background-color: #FBA8A6;
  color: #1D2939;
  border-radius: 9px;
  padding: 16px;
  position: relative;
}

.catalog_cardIcon {
  background-color: #8A76B5;
  border-radius: 50%;
  padding: 7px 7px;
}

.catalog_cardTwoIcon {
  background-color: #F66D69;
  border-radius: 50%;
  padding: 7px 7px;
}

.model_cardOne {
  background-color: #F5E5CE;
  color: #1D2939;
  border-radius: 9px;
  padding: 16px;
  position: relative;
}

.model_cardOneIcon {
  background-color: #E6BC8D;
  border-radius: 50%;
  padding: 7px 7px;
}

.model_cardTwoIcon {
  background-color: #999999;
  border-radius: 50%;
  padding: 7px 7px;
}

.model_cardTwo {
  background-color: #D6D6D6;
  color: #1D2939;
  border-radius: 9px;
  padding: 16px;
  position: relative;
}

.category_tabs {
  min-height: auto !important;
}

/* .category_tabs .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  min-height: 8px !important;
} */

.category_tabs .Mui-selected {
  background-color: #1D2939 !important;
  color: white !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 16px;
  border-radius: 8px;
  text-transform: none !important;
  font-family: "Inter", sans-serif !important;
}

.category_tabs .tabs_exampleText {
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 16px;
  text-transform: none !important;
  color: #1D2939;
  font-family: "Inter", sans-serif !important;
  padding: 8px 16px !important;
  min-width: auto !important;
  min-height: 8px !important;
  letter-spacing: 0;
}

.category_tabs .css-1aquho2-MuiTabs-indicator {
  background-color: transparent !important;
}

.pattern_img {
  position: absolute;
  right: 0px;
  top: 0px;
}

.manufacturePattern_img {
  position: absolute;
  left: 45%;
  bottom: 36%;
}

.custom_tab .css-19kzrtu {
  padding: 0px !important;
}

.quick_button {
  border: 1px solid #562658 !important;
  background-color: #F8ECFB !important;
  color: #562658 !important;
  border-radius: 4px !important;
  padding: 7.5px 9px !important;

}

.quick_button span {
  margin-left: 4px;
}

/* .quick_dropdown div div div {
  left: 1160px !important;
} */
.assets_items {
  background-color: #F2F4F7 !important;
  padding: 10px !important;
  margin: 6px !important;
  border-radius: 4px !important;
  border: 1px solid #EAECF0 !important;
}

.notification_icon {
  border: 1px solid #1D2939;
  padding: 6.5px 7px;
  margin-left: 12px;
  margin-right: 12px;
  border-radius: 6px !important;
}

.search_iconButton {
  align-items: center;
  display: flex !important;
  background-color: #562658;
  padding: 6px 9px;
  border-radius: 8px !important;
  margin-left: 6px;
  min-height: 36px;
}

.location_field div {
  border-radius: 8px !important;
  /* padding: 0px !important; */
}

.location_field label {
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 16px;
  color: #98A2B3 !important;
  ;
  font-family: "Inter", sans-serif !important;
  top: -5px !important;
}

.location_field fieldset {
  border: 1px solid #EAECF0 !important;
}

.location_field legend {
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 16px;
  color: #98A2B3 !important;
  ;
  font-family: "Inter", sans-serif !important;
}

.location_field input {
  padding: 0.5px 4px !important;
  width: 105px;
  min-width: 105px !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 16px;
  color: #98A2B3 !important;
  ;
  font-family: "Inter", sans-serif !important;
}

.location_field label.MuiFormLabel-filled,
.location_field label.Mui-focused {
  top: 0 !important;
  transform: translate(14px, -9px) scale(1);
}

.location_field button svg {
  color: #98A2B3 !important;
}

.location_field .MuiAutocomplete-clearIndicator {
  top: 4px;
}

.category_field div div input {
  padding: 9.4px 14px !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 16px;
  color: #98A2B3 !important;
  font-family: "Inter", sans-serif !important;

}

.dashboard_breadcrumb {
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 16px;
  color: #667085 !important;
  font-family: "Inter", sans-serif !important;
}

.dashboard_breadcrumbTwo {
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 16px;
  color: #1D2939 !important;
  font-family: "Inter", sans-serif !important;
  margin-left: 4px;
}

html {
  scroll-behavior: smooth;
}

#section1 {
  height: 600px;
  background-color: pink;
}

#section2 {
  height: 600px;
  background-color: yellow;
}

#section4 {
  height: 600px;
  background-color: pink;
}

#section3 {
  height: 600px;
  background-color: yellow;
}

/* .tab_scrollContent {
  height:500px;
  overflow-y:scroll;
  margin-top: 10px;
} */
.tab_scrollContent {
  /* height:500px; */
  overflow-y: auto;
  margin-top: 18px;
}

.catalog_tabView {
  width: -webkit-fill-available !important;
  width: -moz-available !important;
  width: 100%;
  padding-right: 30px !important;
}

/* .tab_design {
  overflow-y: auto;
  height: 300px;
  border: 5px solid red;
} */
.quick_dropdown .MuiPaper-root {
  left: 1160px !important;

}

.tab_scrollContent::-webkit-scrollbar {
  height: 7px;
}

.tab_scrollContent::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
}

.tab_scrollContent::-webkit-scrollbar-thumb {
  height: 5px;
  background-color: #c5c1c1;
  border-radius: 3px;
}

.tab_scrollContent::-webkit-scrollbar-thumb:hover {
  background-color: #c5c1c1;
}

.tab_scrollContent::-webkit-scrollbar:vertical {
  display: none;
}

.screenShot_Tabcard {
  border: 1px solid #EAECF0 !important;
  box-shadow: 0px 24px 48px -12px #1018280A !important;
  height: 100% !important;
}

.dashboard_card {
  color: #1D2939;
  border-radius: 9px;
  /* padding: 6px;  */
  position: relative;
  width: calc(20% - 10px);
  cursor: pointer;
  /* height: 141px; */
  /* width: 205px;
  height: 289px; */
}

.dashboard_cardshadow {
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  border: 1px solid #EAECF0;
  box-shadow: none !important;
  height: 100% !important;
  position: relative;
  /* max-height: 120px;
  min-height: 120px; */
  /* height: 141px; */
}

/* .dashboard_card {
  color: rgb(17, 24, 39);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  background-color: #FFFFFF;
} */
.dashboard_cardshadow:hover {
  background: #F0D9F5;
  border: 1px solid #E6BAED;
  border-radius: 16px;
}

.dashboard_cardshadow :hover .location_icon {
  background-color: #a749ac !important;
}

.dashboardPattern_img {
  width: 163px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
}
.dashboardPattern_img svg {
  width: 100%;
  height: 100%;
}
.dashboard_cardshadow:hover .dashboardPattern_img svg circle {
  stroke: rgba(0, 0, 0, .04);
}
.alert_card .dashboardPattern_img svg circle {
  stroke: #653A67;
}
.dashboard_cardshadow:hover .icon_holder svg {
  color: #fff;
}

.location_icon {
  background-color: #FAD58F;
  border-radius: 50%;
  color: #DD950E;
  display: flex;
  padding: 7px 7px;
  line-height: 1.4rem !important;
}

.dashboard_cardIcon {
  background-color: #DFD3FF;
  border-radius: 50%;
  display: flex;
  padding: 7px 7px;
  line-height: 1.4rem !important;
  color: #7B5CCC;
}

.dashboard_cardshadow :hover .dashboard_cardIcon {
  background-color: #a749ac !important;
  /* color: white; */
}

.dashboard_card :hover .location_icon {
  /* color: white; */
}

#assets-search-box {
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 16px;
  color: #98A2B3 !important;
  font-family: "Inter", sans-serif !important;
}

.ticket_icon {
  background-color: #FFB98B;
  border-radius: 50%;
  display: flex;
  padding: 7px 7px;
  line-height: 1.4rem !important;
  color: #DD6212;
}

.dashboard_cardshadow :hover .ticket_icon {
  background-color: #a749ac !important;
  /* color: white; */
}

.active_assetsIcon {
  background-color: #ABEFFE;
  border-radius: 50%;
  display: flex;
  padding: 7px 7px;
  line-height: 1.4rem !important;
  color: #0B7B94;
}

.dashboard_cardshadow :hover .active_assetsIcon {
  background-color: #a749ac !important;
  /* color: white; */
}

.dashboard_alertcard {
  border-radius: 9px;
  /* padding: 2px;  */
  position: relative;
  /* height: 209px;
  width: 205px; */
  /* margin-left: 5px; */
  /* height: 209px; */
  /* height: 141px; */
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  box-shadow: none !important;
}

.alert_card {
  background-color: #542456;
  color: white;
  border-radius: 16px;
  position: relative;
  height: 100%;
  box-shadow: none !important;
}

.alert_icon {
  background-color: white;
  border-radius: 50%;
  padding: 7px 7px;
}

.alert_detailstext {
  color: white;
  margin-top: 10px;
}

.alert_detailstext p {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  font-family: "Inter", sans-serif !important;
  margin-top: 8px;
}

.alert_detailstext h5 {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  font-family: "Archivo", sans-serif !important;
}

.assets_statusText h5 {
  color: #1D2939 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  font-family: "Inter", sans-serif !important;
}

.assets_statusText p {
  color: #667085 !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  font-family: "Inter", sans-serif !important;
  margin-top: 4px;
}

.chart_button {
  /* background-color: #F2F4F7; */
  color: rgb(152, 162, 179) !important;
  border: none;
  border-radius: 5px;
  font-size: 10px !important;
  font-weight: 500 !important;
  font-family: "Inter", sans-serif !important;
}

.mark_button {
  background-color: #FCF6FD;
  border: 1px solid #562658;
  padding: 10px 90px;
  color: #562658;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 16px !important;
  border-radius: 4px;
  margin-top: 10px;
}

.mark_button:hover {
  background-color: #FCF6FD !important;
}

.alert_img {
  position: relative;
}

.alert_img img {
  width: 50px;
  height: 40px;
  border-radius: 4px;
}

.alert_img p {
  color: #1D2939;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 15px !important;
  margin-left: 5px;
  font-family: "Inter", sans-serif !important;
}

.alert_disableimg img {
  position: relative;
}

.alert_disableimg img {
  width: 50px;
  height: 40px;
  border-radius: 4px;
}

.alert_disableimg p {
  color: #667085 !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 15px !important;
  margin-left: 5px;
  font-family: "Inter", sans-serif !important;
}

.dot {
  width: 6px;
  height: 6px;
  background-color: #8C3893;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  right: -1%;
  /* bottom: 0%; */
  top: -8%;
}

.justNow_text {
  color: #1D2939 !important;
  font-size: 10px !important;
  font-weight: 500 !important;
  line-height: 16px !important;
  font-family: "Inter", sans-serif !important;
}

.disablejustNow_text {
  color: #667085 !important;
  font-size: 10px !important;
  font-weight: 500 !important;
  line-height: 16px !important;
  font-family: "Inter", sans-serif !important;
}

.category_tabs div span {
  background-color: transparent !important;
}

.card_chartBox {
  height: 450px;
  margin-top: 17px;
  border: 1px solid #EAECF0;
  box-shadow: none !important;
}

.assets_catalog_header {
  color: black;
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 32px !important;
  font-family: "Archivo", sans-serif !important;
  margin-bottom: 10px;
}

.accordione_iconPosition {
  position: relative;
  /* box-shadow: none !important; */
  /* margin-top: 10px; */
  /* background-color: white; */
}

/* .accrodione_details {
  padding: 0px 0px 0px !important;
} */
.alert_recent {
  color: #667085 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  font-family: "Inter", sans-serif !important;
}

.filter_box {
  border: 1px solid #EAECF0;
  border-radius: 8px;
  padding: 17px;
  background-color: #FFFFFF;
}

.apply_filterButton {
  background-color: #F8ECFB;
  border: 1px solid #562658 !important;
  color: #562658;
  border-radius: 4px;
  padding: 3px 16px;
  margin-right: 8px;
  margin-top: 10px;
}

.apply_filterButton :hover {
  background-color: #FCF6FD !important;
}

.serialNumber_field div {
  padding: 11.5px 14px !important;
}

.serialNumber_field label {
  top: -10% !important;
}

.serialNumberText_field label {
  top: -10% !important;
}

.export_field {
  background-color: #EAECF0 !important;
  /* border: 1px solid #D0D5DD !important; */
  border-radius: 4px;
}

.group_field {
  background-color: #F8ECFB !important;
  border: 1px solid #562658 !important;
  border-radius: 4px;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 16px;
  font-family: "Inter", sans-serif !important;
  color: #562658 !important;
  padding: 4px 14px;
  margin-right: 12px;
  max-height: 32px;
}

.group_field svg {
  color: #562658 !important;
  margin-right: 4px !important;
}

.export_field div {
  padding: 8.15px 14px !important;
  min-height: auto !important;
  max-height: 32px !important;
}

.export_field fieldset,
.export_field:hover fieldset {
  border-color: #D0D5DD !important;
}

.group_field div {
  padding: 11.5px 14px !important;
}

.export_formcontrol label {
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 16px;
  color: #1D2939;
  font-family: "Inter", sans-serif !important;
  top: -10% !important;
}

.group_formcontrol label {
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 16px;
  color: #562658;
  font-family: "Inter", sans-serif !important;
  top: -10% !important;
}

.cancel_button {
  background-color: #EAECF0;
  border: 1px solid #D0D5DD;
  color: #1D2939 !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 16px;
  font-family: "Inter", sans-serif !important;
  padding: 8px 18px !important;
  border-radius: 4px;
  margin-right: 5px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.create_button {
  background-color: #F8ECFB;
  border: 1px solid #562658;
  color: #562658 !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 16px;
  font-family: "Inter", sans-serif !important;
  padding: 8px 18px !important;
  border-radius: 4px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.create_button i.ti {
  font-size: 16px;
  padding-right: 4px;
}

.devices_text {
  font-size: 14px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 20px;
  margin-bottom: 13px !important;
  font-family: "Inter", sans-serif !important;
}

.menuList_itemText span {
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 16px !important;
  font-family: "Inter", sans-serif !important;
  color: #1D2939 !important;
}

.category_tabs div span {
  background-color: transparent !important;
}

.screenShot_Tabcard {
  margin-bottom: 15px;
}

.card_chartBox {
  height: 480px;
}

.assets_catalog_header {
  color: black;
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 32px !important;
  font-family: "Archivo", sans-serif !important;
}

.accordine_icon .MuiAccordionSummary-expandIconWrapper {
  width: 100%;
}

.accordine_icon .MuiAccordionSummary-expandIconWrapper svg {
  margin: 0 auto;
}

.accordine_icon .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
  transform: rotate(0deg) !important;
}

.accordine_icon .MuiAccordionSummary-expandIconWrapper {
  transform: rotate(180deg) !important;
}

.accordione_iconPosition {
  padding-bottom: 0 !important;
  box-shadow: none;
}

.accordione_iconPosition .accordine_icon {
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  padding: 0;
  min-height: 33px !important;
  max-height: 33px;
}

.accordione_iconPosition .accrodione_details {
  padding: 0;
  overflow: hidden;
}

.alert_recent {
  color: #667085 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  font-family: "Inter", sans-serif !important;
}

.filter_box {
  border: 1px solid #EAECF0;
  border-radius: 8px;
  padding: 15px 17px;
  background-color: #FFFFFF;
}

.apply_filterButton {
  background-color: #F8ECFB;
  border: 1px solid #562658 !important;
  color: #562658;
  border-radius: 4px;
  padding: 5px 12px !important;
  margin-right: 8px;
  margin-top: 18px;
}

.apply_filterButton:hover {
  background-color: #FCF6FD !important;
}

.apply_filterButton :hover {
  background-color: #FCF6FD !important;
}

.serialNumber_field > div {
  padding: 9px 14px !important;
  padding-right: 32px !important;
}

.serialNumber_field label {
  color: #1D2939 !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 16px !important;
  font-family: "Inter", sans-serif !important;
  top: -16% !important;
}

.serialNumberText_field {
  color: #1D2939 !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 16px !important;
  font-family: "Inter", sans-serif !important;
}

.serialNumberText_field label {
  top: -16% !important;
  color: #1D2939 !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 16px !important;
  font-family: "Inter", sans-serif !important;
}

/* .serialNumberText_field div {
    border:1px solid #EAECF0;
  } */
.serialNumberText_field div div input {
  padding: 9px 14px !important;
}

.filterText_field label {
  top: -30% !important;
  color: white !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 16px !important;
  font-family: "Inter", sans-serif !important;
}

.filterText_field input::placeholder {
  color: #1D2939 !important;
}

.filterText_field div div input {
  padding: 10px 14px !important;
  font-size: 10px !important;
}

.filterText_field .search_dropdown_holder {
  height: 100%;
}

.filterText_field .search_dropdown_holder input {
  padding: 2px 5px !important;
  color: #1D2939 !important;
  font-size: 12px !important;
  font-weight: 500 !important
}

.filterText_field .search_dropdown_holder input + .MuiAutocomplete-endAdornment {
  top: auto;
}

.filterText_field .date_pickr .helpertext {
  display: none;
}

.add_anotherButton {
  background-color: transparent !important;
  color: #562658 !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 16px !important;
  font-family: "Inter", sans-serif !important;
  padding: 0 !important;
  margin-top: 6px;
  display: flex !important;
}

.filter_icon {
  background-color: #FFFFFF;
  border: 1px solid #562658;
  padding: 6px 7px;
  margin-right: 12px;
  border-radius: 4px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.filter_icon i.ti {
  font-size: 18px;
  color: #562658;
}

.add_OrTabs {
  min-height: 0 !important;
}

.add_OrTabs .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  min-height: 8px !important;
}

.add_OrTabs .Mui-selected {
  background-color: #027A48 !important;
  color: white !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 16px;
  border-radius: 9px;
  text-transform: none !important;
  font-family: "Inter", sans-serif !important;
  color: #562658 !important;
  padding: 4px 14px;
  margin-right: 12px;
}

.cstm_select_formcontrol label {
  min-width: 60px !important;
}

.add_OrTabs .tabs_exampleText {
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 16px;
  text-transform: none !important;
  color: #1D2939;
  font-family: "Inter", sans-serif !important;
  top: -8px !important;
}

.cstm_select_formcontrol label.MuiFormLabel-filled,
.cstm_select_formcontrol label.Mui-focused {
  top: 0 !important;
  transform: translate(14px, -9px) scale(1);
}

.cstm_select_formcontrol legend {
  font-family: "Inter", sans-serif !important;
  font-size: 12px;
}

.cstm_select_formcontrol .placeholder_title {
  font-family: "Inter", sans-serif !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 16px;
  color: #1D2939;
}

.group_formcontrol label {
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 16px;
  color: #562658;
  font-family: "Inter", sans-serif !important;
  top: -10% !important;
  min-width: 60px !important;
  background-color: #e6e6e6;
}

.add_OrTabs .css-1aquho2-MuiTabs-indicator {
  background-color: transparent !important;
}

.dropdown_listGroup {
  max-height: 300px;
  min-height: 300px;
  width: 220px;
  border-radius: 8px;
  border: 1px solid #D0D5DD;
}

.add_assets {
  background-color: #4A1E4C;
  color: white;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 16px;
  text-transform: none !important;
  font-family: "Inter", sans-serif !important;
  padding: 7px 26px !important;
  border-radius: 4px;
  margin-right: 5px;
  margin-top: 12px;
  border: 1px solid #EAECF0;
  display: flex;
  align-items: center;
  border-radius: 4px !important;
  padding: 9px 15.4px !important;
}

.add_assets:hover {
  background-color: #4A1E4C !important;
  color: white !important;
}

.add_assets .material-icons {
  margin-right: -4px !important;
}

.checkbox_list {
  padding: 8px 12px;
  display: flex;
  align-items: center;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 16px;
  text-transform: none !important;
  color: #1D2939;
  font-family: "Inter", sans-serif !important;
  border-radius: 4px;
}

.allAssets_text {
  font-size: 18px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 28px !important;
  font-family: "Archivo", sans-serif !important;
  margin-right: 5px;
  /* margin-bottom: 13px !important; */
  color: #1D2939 !important;

}

.group_columnText {
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 20px;
  text-transform: none !important;
  color: #000000;
  font-family: "Inter", sans-serif !important;
  padding: 10px;
}

.add_OrTabs .Mui-selected {
  background-color: #027A48 !important;
  color: white !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 16px;
  border-radius: 9px;
  text-transform: none !important;
  font-family: "Inter", sans-serif !important;
  min-width: 60px !important;
}

.add_OrTabs .tabs_exampleText {
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 16px;
  text-transform: none !important;
  color: #1D2939;
  font-family: "Inter", sans-serif !important;
  min-width: 60px !important;
  background-color: #e6e6e6;
}

.add_OrTabs .css-1aquho2-MuiTabs-indicator {
  background-color: transparent !important;
}

.dropdown_listGroup {
  max-height: 300px;
  min-height: 300px;
  width: 220px;
  border-radius: 8px;
  border: 1px solid #D0D5DD;
}

/* .checkbox_list {
        padding: 10px;
        display: flex;
        align-items: center;
        font-size: 12px !important;
        font-style: normal;
        font-weight: 500 !important;
        line-height: 16px;
        text-transform:none !important;
        color: #1D2939;
        font-family: "Inter", sans-serif !important;
      } */
.group_columnText {
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 20px;
  text-transform: none !important;
  color: #000000;
  font-family: "Inter", sans-serif !important;
  padding: 11px;
}

.list_text,
.list_text .MuiFormControlLabel-label {
  margin-left: 6px;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 16px;
  text-transform: none !important;
  color: #1D2939;
  font-family: "Inter", sans-serif !important;
}
.list_text .MuiFormControlLabel-label {
  margin-left: 0;
}

.checkbox_padding {
  padding: 0px !important;
  margin-right: 6px !important;
}

.ag-header-container {
  background-color: #F2F4F7 !important;
  border: 1px solid #EAECF0 !important;
}

.ag-theme-alpine .ag-header-row:not(:first-child) .ag-header-cell {
  border-top: none !important;
  border-top-color: none !important;
  border-top-color: none !important;
}

.ag-header-cell-label {
  color: #1D2939 !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 600 !important;
  text-transform: none !important;
  font-family: "Inter", sans-serif !important;
}

div.ag-theme-alpine div.ag-row {
  border-width: 1px 0 2px 0;
}

div.ag-theme-alpine div.ag-row .ag-cell {
  color: #1D2939 !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500 !important;
  text-transform: none !important;
  font-family: "Inter", sans-serif !important;
  border: 1px solid #eaecf0;
  border-width: 0 0.5px;
  line-height: 25px !important;
  align-content: center;
  word-break: break-word;
  /* height: 100%; */
}

.ag-theme-alpine .ag-row .ag-cell[aria-expanded="true"],
.ag-theme-alpine .ag-row .ag-cell[aria-expanded="false"] {
  background: #FFF7E7;
}

.ag-pinned-right-header {
  background-color: #F2F4F7 !important;
  border: 1px solid #EAECF0 !important;
}

.ag-theme-alpine .ag-header-cell {
  border-top: none !important;
}

.ag-theme-alpine .ag-row {
  border-color: #F2F4F7 !important;
  border-color: var(--ag-row-border-color, var(--ag-secondary-border-color, #F2F4F7)) !important;
}

.ag-theme-alpine .ag-row-odd {
  background-color: white !important;
  background-color: var(--ag-odd-row-background-color, white) !important;
}

.ag-theme-alpine .ag-rich-select-virtual-list-item {
  margin-left: 15px !important;
  height: 30px !important;
}

.ag-rich-select-row {
  border: 1px solid #000000 !important;
  border-radius: 16px !important;
  padding: 6px 12px !important;
  background-color: transparent;
  flex: none !important;
  margin-top: 8px !important;

}

.ag-theme-alpine .ag-root-wrapper {
  border-radius: 8px !important;
  border-color: #EAECF0 !important;
  border-color: var(--ag-border-color, #EAECF0) !important;
}

.ag-row-animation .ag-row.ag-after-created:hover {
  background-color: transparent !important;
}

.ag-row-animation .ag-row.ag-after-created:hover {
  background-color: transparent !important;
}

.ag-pinned-right-cols-container {
  width: 123px !important;
  max-width: 123px !important;
  min-width: 123px !important;
}

.ag-theme-alpine .ag-header {
  border-bottom: solid 1px;
  border-bottom-color: #EAECF0 !important;
  border-bottom-color: var(--ag-border-color, #EAECF0) !important;
}

.ag-theme-alpine .ag-pinned-right-header {
  width: 140px !important;
  max-width: 140px !important;
  min-width: 140px !important;
}

.ag-theme-alpine .ag-body-horizontal-scroll:not(.ag-scrollbar-invisible) .ag-horizontal-right-spacer:not(.ag-scroller-corner) {
  width: 139px !important;
  max-width: 139px !important;
  min-width: 139px !important;
}

.ag-theme-alpine .ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(.ag-cell-range-single-cell) {
  border-left: solid 1px;
  border-left-color: #F2F4F7 !important;
  border-left-color: var(--ag-border-color, #F2F4F7) !important;
}

.ag-theme-alpine .ag-body-horizontal-scroll:not(.ag-scrollbar-invisible) .ag-horizontal-right-spacer:not(.ag-scroller-corner) {
  border-left: solid 1px;
  border-left-color: #F2F4F7 !important;
  border-left-color: var(--ag-border-color, #F2F4F7) !important;
}

.assigned_list {
  border: 1px solid #000000;
  border-radius: 16px;
  padding: 5px 8px;
  display: inline-flex;
  align-items: center;
  margin-bottom: 9px;
  /* justify-content: flex-start; */
  flex-wrap: wrap;
}

.dot_circle {
  height: 8px;
  width: 8px;
  /* background-color: #C366CF; */
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.listing_dropdown {
  background-color: white;
  /* border:1px solid #D0D5DD; */
  padding: 10px 30px 10px 10px;
  border-radius: 8px;
  height: 264px;
  overflow-y: scroll;
}

.ag-theme-alpine .ag-popup-editor {
  border: solid 1px;
  border-color: #D0D5DD;
  border-color: var(--ag-border-color, #D0D5DD) !important;
  background: #fff;
  background: var(--ag-background-color, #fff);
  border-radius: 8px !important;
  box-shadow: none !important;
  padding: 6px;
  background-color: #f8f8f8;
  background-color: var(--ag-control-panel-background-color, #f8f8f8);
}

.ag-theme-alpine .ag-popup-editor {
  border: 1px solid #D0D5DD !important;
  border-radius: 8px !important;
}

.ag-theme-alpine .ag-column-select-virtual-list-item {
  margin: 8px 12px 0 !important;
}

.assigned_text {
  color: #000000 !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500 !important;
  text-transform: none !important;
  font-family: "Inter", sans-serif !important;
}

.listing_dropdown .assigned_list .assigned_text {
  max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.userlisting_dropdown {
  background-color: white;
  color: #000;
  /* border:1px solid #D0D5DD; */
  padding: 10px;
  padding-right: 2px;
  border-radius: 8px;
  max-width: 220px;
  height: 183px;
  overflow-y: scroll;
}

.userlisting_dropdown li {
  word-break: break-all;
}

.cstm_popover_holder {
  padding: 10px;
}

.cstm_popover_holder .action_list {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 0;
}

.cstm_popover_holder .action_list:not(:last-child) {
  margin-bottom: 5px;
}

.cstm_popover_holder .action_listText {
  padding-left: 5px;
}

.search_listingFiled {
  box-shadow: none !important;
  border: 1px solid #EAECF0 !important;
  color: #98A2B3 !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500 !important;
  text-transform: none !important;
  font-family: "Inter", sans-serif !important;
  border-radius: 8px;
  padding: 9px;
}
.userlisting_dropdown .search_holder {
  width: 200px;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  padding: 10px 0;
  background: #fff;
  z-index: 1;
}
.userlisting_dropdown .search_listingFiled:focus {
  outline-color: #EAECF0;
}
.userlisting_dropdown .list_outer_holder {
  margin-top: 30px;
}

.search_text {
  color: #98A2B3 !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500 !important;
  text-transform: none !important;
  font-family: "Inter", sans-serif !important;
}

.userlist_itemsText span {
  color: #1D2939 !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500 !important;
  text-transform: none !important;
  font-family: "Inter", sans-serif !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.userlist_items div {
  padding: 5px !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.userlist_items:hover {
  background-color: #F2F4F7;
  border-radius: 4px;
  /* padding: 6px; */
}

.action_list {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.action_listText {
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500 !important;
  text-transform: none !important;
  font-family: "Inter", sans-serif !important;
  color: #1D2939 !important;
}

.two_btns_holder {
  display: inline-flex;
  flex-wrap: wrap;
  background: #f2f4f7;
  border-radius: 8px;
}

.two_btns_holder button {
  background: #f2f4f7;
  border-radius: 8px;
  color: #1D2939 !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 16px !important;
  font-family: "Inter", sans-serif !important;
  padding: 9px 19px !important;
}

.two_btns_holder button.active {
  background: #027948;
  color: #fff !important;
}

.delete_filter_holder .material-icons {
  /* position: absolute;
  top: 5px;
  right: 0; */
  display: inline-block;
  color: #F66D69;
}

.between_btns_holder {
  text-align: right;
  margin-bottom: 5px;
}

.between_btns_holder .two_btns_holder {
  display: inline-block;
}

.archive_button {
  background-color: #F8ECFB;
  border: 1px solid #562658;
  color: #562658 !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 16px;
  font-family: "Inter", sans-serif !important;
  padding: 7px 26px !important;
  border-radius: 4px;
}

@media (min-width: 1800px) {
  .tab_scrollContent {
    /* height:auto; */
    overflow-y: scroll;
  }
}

.checkbox_padding {
  padding: 0px !important;
}

.pie_chart .apexcharts-canvas {
  overflow: auto;
}

/* .chart_height {
  min-height: 270px;
  max-height: 270px;
  overflow: scroll;
 } */
/* .pie_chart .apexcharts-canvas {
  position: relative;
  user-select: none;
  left: 12%;
  left: -6%;
} */
.pie_chart .apexcharts-legend {
  padding: 13px 0px !important;
  top: 30px !important;
}

.pie_chart .apexcharts-legend-series {
  margin: 8px 5px !important;
}

.apexcharts-text .apexcharts-yaxis-title-text {
  color: #EEEEEE !important;
}

.outLine_color .MuiOutlinedInput-notchedOutline {
  border: 1px solid #EAECF0 !important;
}

/* .header_toolbar {
transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
} */
/* .MuiPaper-elevation4-32 {
box-shadow: none !important;
} */
.header_toolbarHeight {
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 7.8rem !important;
  box-shadow: none !important;
  border-bottom: 1px solid #EAECF0;
}

.profile_img {
  border-radius: 8px !important;
}

.profile_buttonimg:hover {
  background-color: transparent !important;
}

.catalogCard_scroll::-webkit-scrollbar {
  height: 7px;
}

.catalogCard_scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
}

.catalogCard_scroll::-webkit-scrollbar-thumb {
  height: 5px;
  background-color: #dbd9d9;
  border-radius: 3px;
}

.scatalogCard_scroll::-webkit-scrollbar-thumb:hover {
  background-color: #dbd9d9;
}

.catalogCard_scroll::-webkit-scrollbar:vertical {
  display: none;
}

.assets_listSelect {
  background-color: #FCFCFD !important;
}

.assets_listSelect fieldset {
  border: 1px solid #D0D5DD !important;
}

.status_text,
.cstm_purple_accordion {
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 16px;
  text-transform: none !important;
  color: #1D2939;
  font-family: "Inter", sans-serif !important;
  margin-bottom: 8px !important;
}
.status_text.light {
  font-weight: 400 !important;
}
.status_text.mb-0 {
  margin-bottom: 0 !important;
}
.serial_numberField div input {
  padding: 12.5px 14px !important;
}

.serial_numberField fieldset {
  border: 1px solid #D0D5DD !important;
}

.custom_tabContent .MuiBox-root {
  padding: 0px !important;
}

.usercustom_tab .MuiBox-root {
  padding-top: 0px !important;
}

.required_tabs {
  color: #1D2939 !important;
  background-color: #FDCCCB !important;
  border: 1px solid #FDCCCB !important;
  border-radius: 4px;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 20px;
  text-transform: none !important;
  /* padding: 10px; */
}

.required_tabsText {
  min-height: 3px !important;
  /* padding: 8px 16px !important; */
  padding: 0px !important;
  color: #1D2939 !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 20px;
  text-transform: none !important;
}

.required_tabs .MuiTabs-indicator {
  background-color: transparent !important;
}

.upload_imgBox {
  background-color: #F9FAFB;
  border: 1px dashed #F2F4F7;
  padding: 60px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

#upload-photo {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.back_button {
  background-color: #EAECF0;
  border: 1px solid #D0D5DD;
  padding: 10px 90px;
  color: #1D2939;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 16px !important;
  border-radius: 4px;
  margin-right: 10px;
}

.back_button:hover {
  background-color: #EAECF0 !important;
}

.next_button {
  background-color: #F8ECFB;
  border: 1px solid #562658;
  padding: 10px 90px;
  color: #562658;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 16px !important;
  border-radius: 4px;
}

.next_button:hover {
  background-color: #FCF6FD !important;
}

.cstm-date>div {
  background: #FCFCFD;
  width: 100% !important;
  font-size: 24px !important;
}

.cstm-date fieldset {
  border: 1px solid #D0D5DD !important;
}

.cstm-date input {
  padding: 11.5px 14px !important;
}

/* #fuse-toolbar header_toolbar {
box-shadow: none !important;
} */

/* .apexcharts-legend {
  max-height: 0 !important;
  position: unset !important;
 } */

.translate-20-holder .apexcharts-pie {
  transform: translateX(-20%);
}

.translate-20-holder .chart_btn_holder {
  margin-left: 18%;
}

.card_chartBox .apexcharts-legend-series {
  width: 120px;
  display: inline-flex !important;
}

.card_chartBox .apexcharts-legend-series .apexcharts-legend-text {
  width: calc(100% - 15px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.textfield_holder>div {
  height: 100%;
}

@media (min-width: 1800px) {
  .tab_scrollContent {
    /* height:auto; */
    overflow-y: scroll;
  }
}

.mapboxgl-ctrl-attrib {
  display: none;
}

@media (max-width: 1800px) {
  .translate-20-holder .apexcharts-pie {
    transform: translateX(-15%);
  }

  .translate-20-holder .chart_btn_holder {
    margin-left: 22%;
  }
}

@media (max-width: 1550px) {
  .translate-20-holder .apexcharts-pie {
    transform: translateX(-11%);
  }

  .translate-20-holder .chart_btn_holder {
    margin-left: 22%;
  }
}

@media (max-width: 1450px) {
  .translate-20-holder .apexcharts-pie {
    transform: translateX(-9%);
  }

  .translate-20-holder .chart_btn_holder {
    margin-left: 22%;
  }
}

@media (max-width: 1400px) {
  .translate-20-holder .apexcharts-pie {
    transform: translateX(-7%);
  }

  .translate-20-holder .chart_btn_holder {
    margin-left: 18%;
  }
}

@media (max-width: 1350px) {
  .translate-20-holder .chart_btn_holder {
    margin-left: 18%;
  }
}

@media (max-width: 1200px) {
  .translate-20-holder .chart_btn_holder {
    margin-left: 18%;
  }
}

@media (max-width: 800px) {
  .dashboard_card {
    width: calc(50% - 10px);
  }
}